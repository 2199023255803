<template>
  <div>
    <b-table
      v-if="appointments.length"
      ref="refRelatedAppointmentsTable"
      class="position-relative has_padding event_list min-height-300 shadow-table"
      :items="appointments"
      responsive
      :fields="tableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <!-- Column: Role -->
      <template #cell(stringID)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'Booking')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id"
            :to="{ name: 'appointment-bookings-show', params: { id: data.item._id } }"
          >
            {{ data.item.stringID }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id not-button"
          >
            {{ data.item.stringID }}
          </b-button>
        </div>
      </template>

      <template #cell(start)="data">
        <div
          v-if="data.item.timeSlotApplicable === false"
          class="text-nowrap"
        >
          <span>-</span>
        </div>
        <div
          v-else
          class="text-nowrap"
        >
          <span>{{ dateFormat(data.item.start) }}</span>
          <div v-if="data.item.start != data.item.end">
            <span>{{ timeFormatFromDateString(data.item.start) }}</span> - <span>{{ timeFormatFromDateString(data.item.end) }}</span>
          </div>
        </div>
      </template>

      <template #cell(activityType)="data">
        <b-badge
          :variant="`${data.item.activityType.color}`"
          class="small-badge-button small-badge-button-activity-list"
          :style="{ '--bg-color': data.item.activityType.color }"
        >
          <span v-if="data.item.eventID">
            {{ data.item.eventID ? data.item.eventID.name : '' }}
          </span>
          <span v-else>
            {{ data.item.activityType ? data.item.activityType.typeName : '' }}
          </span>
        </b-badge>
      </template>

      <template #cell(customerName)="data">
        <div class="text-nowrap">
          <span>{{ data.item.customerName }}</span>
          <br>
          <span>{{ data.item.customerContact ? data.item.customerContact : data.item.customerEmail }}</span>
        </div>
      </template>

      <template #cell(agentName)="data">
        <div class="text-nowrap">
          <span>{{ data.item.agentName }}</span>
          <br>
          <span>{{ data.item.agentCode }}</span>
        </div>
      </template>

      <template #cell(createdBy)="data">
        <div class="text-nowrap">
          <span>{{ data.item.createdBy.name }}</span>
          <br>
          <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${resolveBookingStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status.replace("-", " ") }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template
        v-if="canViewThisAction('show', 'Booking')"
        #cell(actions)="data"
      >
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'Booking')"
            v-b-tooltip.hover.bottom="'Resend Confirmation Message'"
            variant="flat-primary"
            class="send-sms-icon"
            @click="sendSMSAgain(data.item._id)"
          >
            <feather-icon
              icon="SendIcon"
              class=""
              size="18"
            />
          </b-button>
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'Booking')"
              :to="{ name: 'appointment-bookings-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View Booking</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #bottom-row="">
        <td
          colspan="10"
        >
          <b-row>
            <b-col
              md="2"
              sm="2"
              class="my-25"
            >
              <b-form-group
                class="mb-0"
              >
                <label class="d-inline-block text-sm-left mr-50 weight-400 fs-14">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="10"
              sm="10"
              class="my-25"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                align="right"
                prev-text="Prev"
                next-text="Next"
              />
            </b-col>
          </b-row>
        </td>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No service requests found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip, BBadge, BDropdown, BDropdownItem, BPagination, BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    appointments: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: this.appointments.length,
      perPage: 10,
      pageOptions: [10, 15, 20],
      tableColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'start',
          label: 'appt. time',
          sortable: true,
        },
        {
          key: 'activityType',
          label: 'Activity type',
          sortable: true,
        },
        {
          key: 'remarks',
          label: 'remarks',
          sortable: true,
        },
        {
          key: 'agentName',
          label: 'Agent',
          sortable: true,
        },
        {
          key: 'createdBy',
          label: 'Created by',
          sortable: true,
        },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveBookingStatusVariant(status) {
      if (status === 'confirmed') return 'success'
      if (status === 'pending-payment') return 'warning'
      if (status === 'pending-approval') return 'warning'
      if (status === 'waiting-list') return 'secondary'
      if (status === 'draft') return 'secondary'
      if (status === 'cancelled') return 'danger'
      return 'primary'
    },
    sendSMSAgain(id) {
      this.$http.get(`appointment/bookings/${id}/send-confirmation-message`)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
