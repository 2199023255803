<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Editing Contact Information
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana SG</strong> This is the control panel where administrators can edit the content for the customer-facing mobile app.</span>
      </div>
    </b-alert>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="appSettingsForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="submitForm">
          <b-row>
            <b-col cols="md-12">
              <b-form-group
                label="Operating Hours*"
                label-for="h-operating-hours"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Operating Hours"
                  vid="operatingHours"
                  rules="required"
                >
                  <b-form-input
                    id="h-operating-hours"
                    v-model="operatingHours"
                    placeholder="E.g. Monday – Friday 8am to 4pm"
                    :state="(errors.length > 0 || operatingHoursValidation) ? false : null"
                    name="operatingHours"
                    @input="operatingHoursValidation == true ? operatingHoursValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="operatingHoursValidation"
                    class="text-danger"
                  >
                    {{ operatingHoursError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-12">
              <b-form-group
                label="Contact Number*"
                label-for="h-contact-number"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Contact Number"
                  vid="contactNumber"
                  rules="required"
                >
                  <b-form-input
                    id="h-contact-number"
                    v-model="contactNumber"
                    placeholder="Contact Number"
                    :state="(errors.length > 0 || contactNumberValidation) ? false : null"
                    name="contactNumber"
                    @input="contactNumberValidation == true ? contactNumberValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="contactNumberValidation"
                    class="text-danger"
                  >
                    {{ contactNumberError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-12">
              <b-form-group
                label="Email Contact*"
                label-for="h-contact-email"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email Contact"
                  vid="contactEmail"
                  rules="required"
                >
                  <b-form-input
                    id="h-contact-email"
                    v-model="contactEmail"
                    placeholder="E.g. hello@nirvana.com.sg"
                    :state="(errors.length > 0 || contactEmailValidation) ? false : null"
                    name="contactEmail"
                    @input="contactEmailValidation == true ? contactEmailValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="contactEmailValidation"
                    class="text-danger"
                  >
                    {{ contactEmailError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Address*"
                label-for="event-address"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="address"
                  vid="address"
                  rules="required"
                >
                  <b-form-textarea
                    id="event-address"
                    v-model="address"
                    placeholder="Add address"
                    rows="3"
                    name="address"
                    @input="addressValidation == true ? addressValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="addressValidation"
                    class="text-danger"
                  >
                    {{ addressError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-12">
              <b-form-group
                label="Latitude*"
                label-for="h-latitude"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Latitude"
                  vid="latitude"
                  rules="required"
                >
                  <b-form-input
                    id="h-latitude"
                    v-model="latitude"
                    placeholder="Latitude"
                    :state="(errors.length > 0 || latitudeValidation) ? false : null"
                    name="latitude"
                    @input="latitudeValidation == true ? latitudeValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="latitudeValidation"
                    class="text-danger"
                  >
                    {{ latitudeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-12">
              <b-form-group
                label="Longitude*"
                label-for="h-longitude"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Longitude"
                  vid="longitude"
                  rules="required"
                >
                  <b-form-input
                    id="h-longitude"
                    v-model="longitude"
                    placeholder="Longitude"
                    :state="(errors.length > 0 || longitudeValidation) ? false : null"
                    name="longitude"
                    @input="longitudeValidation == true ? longitudeValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="longitudeValidation"
                    class="text-danger"
                  >
                    {{ longitudeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'app-contact-information-index' }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="invalid"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Save Contact Information</span>
              </b-button>
            </li>
          </b-nav>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      operatingHours: '',
      operatingHoursError: 'Valid Operating Hours is required',
      operatingHoursValidation: false,
      contactNumber: '+65',
      contactNumberError: 'Valid Contact is required',
      contactNumberValidation: false,
      contactEmail: '',
      contactEmailError: 'Valid Email is required',
      contactEmailValidation: false,
      address: '',
      addressError: 'Valid Address is required',
      addressValidation: false,
      latitude: '',
      latitudeError: 'Valid Address is required',
      latitudeValidation: false,
      longitude: '',
      longitudeError: 'Valid Address is required',
      longitudeValidation: false,

      // validation rules
      required,
    }
  },
  created() {
    this.$http.get('directory/app/contact-information/show')
      .then(response => {
        if (response.data.exists) {
          const { data } = response.data
          this.operatingHours = data.operatingHours
          this.contactNumber = data.contactNumber
          this.contactEmail = data.contactEmail
          this.address = data.address
          this.latitude = data.latitude
          this.longitude = data.longitude
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    submitForm() {
      this.$refs.appSettingsForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('operatingHours', this.operatingHours)
          formData.append('contactNumber', this.contactNumber)
          formData.append('contactEmail', this.contactEmail)
          formData.append('address', this.address)
          formData.append('latitude', this.latitude)
          formData.append('longitude', this.longitude)

          this.$http.post('directory/app/contact-information/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message ?? '',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'app-contact-information-index' })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'operatingHours') {
                    this.operatingHoursError = validationError.msg
                    this.operatingHoursValidation = true
                  } else if (validationError.param === 'contactNumber') {
                    this.contactNumberError = validationError.msg
                    this.contactNumberValidation = true
                  } else if (validationError.param === 'contactEmail') {
                    this.contactEmailError = validationError.msg
                    this.contactEmailValidation = true
                  } else if (validationError.param === 'address') {
                    this.addressError = validationError.msg
                    this.addressValidation = true
                  } else if (validationError.param === 'latitude') {
                    this.latitudeError = validationError.msg
                    this.latitudeValidation = true
                  } else if (validationError.param === 'longitude') {
                    this.longitudeError = validationError.msg
                    this.longitudeValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
