<template>

  <div class="service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="4"
        lg="4"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ agentDetail.code }} - {{ agentDetail.name }}
            </h1>
            <p class="log_info">
              Created by {{ getCreatedBy() }} on {{ getCreatedAt() }}<br>Last updated on {{ getUpdatedAt() }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="8"
        lg="8"
        cols="12"
      >
        <b-button
          variant="light"
          class="text-primary"
          :to="{ name: 'agency-agents-edit', params: { id: agentDetail._id || 0 } }"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
          />
          Edit
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card class="agent__details">
      <b-row>
        <b-col md="12">
          <b-card
            class="has-padding"
          >
            <b-card-title>
              Agent Details
            </b-card-title>
            <b-card-body>
              <b-row>
                <b-col
                  md="2"
                  class="d-flex align-self-center"
                >
                  <b-avatar
                    size="150"
                    :src="agentDetail.image"
                    :text="resolveAcronymName(agentDetail.name)"
                  />
                </b-col>
                <b-col md="5">
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased d-none d-md-block"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Agent Name
                        </b-th>
                        <b-td>{{ agentDetail.name || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Card Display Name
                        </b-th>
                        <b-td>{{ agentDetail.displayName || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Agent Code
                        </b-th>
                        <b-td>{{ agentDetail.code || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Agency
                        </b-th>
                        <b-td>{{ `${agentDetail.agencyName || '-'} (${agentDetail.agencyCode || '-'}) - ${agentDetail.agencyState || '-'}` }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Country Code
                        </b-th>
                        <b-td>{{ agentDetail.country || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          State Code
                        </b-th>
                        <b-td>{{ agentDetail.state || '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <b-col md="5">
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased d-none d-md-block"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Join Date
                        </b-th>
                        <b-td>{{ agentDetail.joinDate ? dateFormat(agentDetail.joinDate) : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Confirm Date
                        </b-th>
                        <b-td>{{ agentDetail.confirmDate ? dateFormat(agentDetail.confirmDate) : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Expiry Date
                        </b-th>
                        <b-td>{{ agentDetail.expDate ? dateFormat(agentDetail.expDate) : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Status
                        </b-th>
                        <b-td><span :class="agentDetail.status == 'Active' ? 'text-success' : 'text-danger'">{{ agentDetail.status }}</span></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Remarks
                        </b-th>
                        <b-td>{{ agentDetail.remark || '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col md="4">
          <b-card
            class="has-padding"
          >
            <b-card-title>
              Personal Information
            </b-card-title>
            <b-card-body>
              <b-table-simple
                borderless
                responsive
                class="particulars_of_deceased d-md-block"
              >
                <b-tbody>
                  <b-tr>
                    <b-th width="230px">
                      NRIC
                    </b-th>
                    <b-td>{{ agentDetail.encryptedNric || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Sex
                    </b-th>
                    <b-td>{{ agentDetail.sex || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Race
                    </b-th>
                    <b-td>{{ agentDetail.race || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Marital
                    </b-th>
                    <b-td>{{ agentDetail.maritalStatus || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Date of Birth
                    </b-th>
                    <b-td>{{ agentDetail.dob ? dateFormat(agentDetail.dob) : '-' }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            class="has-padding"
          >
            <b-card-title>
              Correspondence Contact
            </b-card-title>
            <b-card-body>
              <b-table-simple
                borderless
                responsive
                class="particulars_of_deceased d-md-block"
              >
                <b-tbody>
                  <b-tr>
                    <b-th width="230px">
                      Primary Contact
                    </b-th>
                    <b-td>{{ agentDetail.personalContact || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Secondary Contact
                    </b-th>
                    <b-td>{{ agentDetail.homeContact || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Email Address
                    </b-th>
                    <b-td>{{ agentDetail.email || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Address
                    </b-th>
                    <b-td>{{ `${agentDetail.address1 || '-'}, ${agentDetail.address2 || '-'}, ${agentDetail.address3 || '-'}` }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Post Code
                    </b-th>
                    <b-td>{{ agentDetail.postCode || '-' }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            class="has-padding"
          >
            <b-card-title>
              Emergency Contact
            </b-card-title>
            <b-card-body>
              <b-table-simple
                borderless
                responsive
                class="particulars_of_deceased d-md-block"
              >
                <b-tbody>
                  <b-tr>
                    <b-th width="230px">
                      Emergency Contact Person
                    </b-th>
                    <b-td>{{ agentDetail.emergencyContactName || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Mobile No.
                    </b-th>
                    <b-td>{{ agentDetail.emergencyContactMobile || '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Relationship
                    </b-th>
                    <b-td>{{ agentDetail.emergencyContactRelation || '-' }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-card>
          <b-card
            class="has-padding"
          >
            <b-card-title>
              Bank Details
            </b-card-title>
            <b-card-body>
              <b-table-simple
                borderless
                responsive
                class="particulars_of_deceased d-md-block"
              >
                <b-tbody>
                  <b-tr>
                    <b-th width="230px">
                      Bank Code
                    </b-th>
                    <b-td>{{ agentDetail.bankCode }} - {{ agentDetail.bankName }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Bank Branch
                    </b-th>
                    <b-td>{{ agentDetail.bankBranch }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Bank Account No.
                    </b-th>
                    <b-td>{{ agentDetail.bankAccountNo }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="canViewThisAction('read', 'File')"
          md="12"
        >
          <b-card
            class="has-padding customer-files__card"
          >
            <b-card-title class="d-flex">
              <span>Agent’s Files</span>
            </b-card-title>
            <b-card-text>
              <b-tabs
                class="purchaser-tabs"
                content-class="mt-2"
              >
                <b-tab
                  title="Service Agent"
                >
                  <ServiceAgentFilesList :related-files="serviceFiles" />
                </b-tab>
                <b-tab
                  lazy
                  title="Sales Agent"
                >
                  <SalesAgentFilesList :sales-files="salesFiles" />
                </b-tab>
              </b-tabs>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card
            title="Attachments"
            class="has-padding"
          >
            <input
              ref="hiddenAllocationFileInput"
              type="file"
              multiple
              capture
              accept="image/jpeg, image/png, application/pdf"
              class="d-none"
              @change="setAttachment"
            >
            <b-row v-if="agentDetail.attachments && agentDetail.attachments.length">
              <!-- Content Left -->
              <b-col
                v-for="(file, key) in agentDetail.attachments"
                :key="key"
                class="content-header-left mb-2"
                cols="12"
                md="4"
              >
                <div
                  class="attachment-item"
                >
                  <b-img
                    v-if="file.type.includes('image')"
                    left
                    height="40"
                    :src="require('@/assets/images/admin/doc_jpg.png')"
                    alt="Left image"
                  />
                  <b-img
                    v-if="file.type.includes('application')"
                    left
                    height="40"
                    :src="require('@/assets/images/admin/doc_file.png')"
                    alt="Left image"
                  />
                  <strong>
                    <b-link
                      :href="file ? file.data : ''"
                      target="_blank"
                    >
                      {{ file ? file.name : '' }}
                    </b-link>
                  </strong>
                  <span>{{ file ? `${file.size}mb` : '' }}</span>
                  <span class="remove__doc">
                    <b-button
                      variant="flat-primary"
                      @click="removeAttachment(file.name, file.data)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-50"
                        size="18"
                      />
                    </b-button>
                  </span>
                </div>
                <div class="doc__desc new_design">
                  <span v-if="file.description">
                    {{ file.description }}
                  </span>
                  <span v-else>
                    <button
                      @click="updateDescription(key)"
                    >
                      Add a description
                    </button>
                  </span>
                  <span
                    v-if="file.description"
                    class="edit__desc"
                  >
                    <b-button
                      variant="flat-primary"
                      @click="updateDescription(key)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                        size="18"
                      />
                    </b-button>
                  </span>
                </div>
              </b-col>
              <b-col
                class="content-header-left mb-2"
                cols="12"
                md="4"
              >
                <b-button
                  variant="link"
                  class="mr-1 md-mb-1"
                  @click="$refs.hiddenAllocationFileInput.click()"
                >
                  <feather-icon
                    icon="UploadIcon"
                    size="20"
                    class="mr-50"
                  />
                  <span
                    class="align-middle"
                    style="font-weight: 500; font-size: 14px;"
                  >
                    Upload
                  </span>
                </b-button>
                <b-button
                  v-if="attachments.length"
                  variant="success"
                  class="mr-1 md-mb-1"
                  @click="uploadFile"
                >
                  Save File
                </b-button>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col>
                <div class="empty_block">
                  <h4>Nothing in here yet</h4>
                  <b-button
                    variant="link"
                    class=""
                    @click="$refs.hiddenAllocationFileInput.click()"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      size="20"
                      class="mr-50"
                    />
                    <span
                      class="align-middle"
                      style="font-weight: 500; font-size: 14px;"
                    >
                      Upload
                    </span>
                  </b-button>
                  <b-button
                    v-if="attachments.length"
                    variant="success"
                    class="mr-1 md-mb-1"
                    @click="uploadFile"
                  >
                    Save File
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="Related to this Agent"
      class="mb-3 mt-3 p-2"
    >
      <b-card-text>
        <b-tabs
          class="purchaser-tabs"
          content-class="mt-2"
        >
          <b-tab
            v-if="canViewThisAction('read', 'ServiceRequest')"
            lazy
            title="Service Requests"
          >
            <RelatedServiceRequests :service-requests="serviceRequests" />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'Booking')"
            lazy
            title="Appointments"
          >
            <RelatedAppointmentRequests :appointments="appointmentList" />
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left pb-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Activity Logs</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <!-- FINANCIAL REPORT -->
        <app-timeline-item
          v-for="(opt, key) in agentDetailActivityLogs"
          :key="key"
          :variant="getVariant(key)"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ opt.title }}</h6>
            <small class="text-muted">{{ dateFormatWithTime(opt.time) }}</small>
          </div>
          <b-button
            v-if="opt.activityDetails && opt.activityDetails.length"
            v-b-toggle="'report-list-with-icon-' + key"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
          >
            Details
            <feather-icon
              icon="ChevronUpIcon"
              class="ml-50 when-open"
              size="16"
            />
            <feather-icon
              icon="ChevronDownIcon"
              class="ml-50 when-closed"
              size="16"
            />
          </b-button>
          <b-collapse
            v-if="opt.activityDetails && opt.activityDetails.length"
            :id="'report-list-with-icon-' + key"
          >
            <b-table-simple
              borderless
              responsive
            >
              <b-tbody>
                <b-tr
                  v-for="(activityDetail, activitykey) in opt.activityDetails"
                  :key="activitykey"
                >
                  <b-th style="width: 300px; max-width: 300px;">
                    {{ activityDetail.title }}
                  </b-th>
                  <b-td>{{ activityDetail.oldValue }}</b-td>
                  <b-td>{{ activityDetail.newValue }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Payment Schedule"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBToggle, VBTooltip, BCollapse, BTab, BTabs, BAvatar,
  BImg, BLink, BCardTitle, BFormTextarea,
} from 'bootstrap-vue'
import store from '@/store/index'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RelatedServiceRequests from './RelatedServiceRequests.vue'
import RelatedAppointmentRequests from './RelatedAppointmentRequests.vue'
import ServiceAgentFilesList from './ServiceAgentFilesList.vue'
import SalesAgentFilesList from './SalesAgentFilesList.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCollapse,
    BTab,
    BTabs,
    // BCardBody,
    BAvatar,
    // BDropdown,
    // BDropdownItem,
    BFormTextarea,
    BImg,
    BLink,
    BCardTitle,

    RelatedServiceRequests,
    RelatedAppointmentRequests,
    ServiceAgentFilesList,
    SalesAgentFilesList,
    AppTimeline,
    AppTimelineItem,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      agentDetail: {},
      fileDetail: {},
      serviceFiles: [],
      salesFiles: [],
      // fileDetail: JSON.parse(localStorage.getItem('customerFileDetail')),
      fileDetailID: '',
      agentDetailActivityLogs: [],
      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      // eslint-disable-next-line global-require
      userAvatar: require('@/assets/images/avatars/2.png'),
      currentTabTitle: 'File Details',
      activeTab: 0,
      attachments: [],
      description: '',
      appointmentList: [],
      serviceRequests: [],
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.fetchAgentDetail()
  },
  methods: {
    getCreatedBy() {
      if (this.agentDetail.createdByNvs) {
        return this.agentDetail.createdByNvs.name
      }
      if (this.agentDetail.createdBy) {
        return this.agentDetail.createdBy
      }

      return ''
    },
    getCreatedAt() {
      if (this.agentDetail.createdAtNvs) {
        return this.dateFormatWithTime(this.agentDetail.createdAtNvs)
      }
      if (this.agentDetail.createdBy) {
        return this.dateFormatWithFormatNTZ(this.agentDetail.createdAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    getUpdatedAt() {
      if (this.agentDetail.updatedAtNvs) {
        return this.dateFormatWithTime(this.agentDetail.updatedAtNvs)
      }
      if (this.agentDetail.createdBy) {
        return this.dateFormatWithFormatNTZ(this.agentDetail.updatedAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    fetchAgentDetail() {
      this.$http.get(`customer/agents/${this.$route.params.id}/show`)
        .then(response => {
          if (response.data.success) {
            this.agentDetail = response.data.agent
            this.serviceFiles = response.data.serviceFiles
            this.salesFiles = response.data.salesFiles
            this.appointmentList = response.data.appointmentList
            this.serviceRequests = response.data.serviceRequests
            document.title = `Agent - ${this.agentDetail.name} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
            const bcLength = store.state.breadcrumb.breadcrumbs.length
            if (bcLength > 1) {
              store.commit('breadcrumb/REMOVE_BREADCRUMB')
            }
            const newMeta = {
              active: true,
              text: `${this.agentDetail.code} - ${this.agentDetail.name}`,
            }
            store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
            this.agentDetailActivityLogs = this.agentDetail.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    removeAttachment(name, url) {
      this.$http
        .post('customer/agents/attachment/remove', { url, name, id: this.$route.params.id })
        .then(response => {
          this.agentDetail.attachments = response.data.data.attachments
          this.agentDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 5 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 5mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('id', this.$route.params.id)
      this.$http.post('customer/agents/attachment/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.agentDetail.attachments = response.data.data.attachments
          this.agentDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveAcronymName(name) {
      if (name) {
        return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
      }

      return ''
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.agentDetail.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },

    saveDescription() {
      const url = this.agentDetail.attachments[this.attachmentKey].data
      this.$http
        .post('customer/agents/attachment/save-description', { url, description: this.description, id: this.agentDetail._id })
        .then(response => {
          this.agentDetail.attachments = response.data.data.attachments
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .timeline-variant-purple .timeline-item-point {
    background-color: #7367F0 !important;
  }
  .tab-parent .nav-item {
    margin-bottom: 0.75rem !important;
  }
  .content-body .card.customer-files__card th {
    padding: 11px 21px !important;
}
  .content-body .card.customer-files__card td {
    padding: 12px 21px !important;
}
</style>

<style lang="scss">
  @import "~@core/scss/base/pages/app-email.scss";
  .agent__details{
       background-color: #F8F8F8;
  }
</style>
