<template>
  <div class="p4b__parent">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div
      class="container p4b__onboarding-method__mob-margin"
    >
      <div
        class="p4b__toc-logo"
      >
        <img
          :src="logoImg"
          alt="Logo"
        >
      </div>
      <div
        class="p4b__onboard-retrieve-singpass"
        :class="isMobile() ? 'p4b__onboard-retrieve-singpass__mob' : 'p4b__onboard-retrieve-singpass__desktop'"
      >
        <div>
          <h2 class="mt-0">
            Retrieve Myinfo with <b-img
              :src="singPassLogo"
              height="28"
            />
          </h2>
          <p>
            Would you like to use Myinfo with Singpass to update your profile details?
          </p>
        </div>
        <div
          v-if="!isMobile()"
          class="p4b__onboard-retrieve-desktop-btn"
        >
          <b-button
            pill
            class="p4b__onboard-retrieve-continue-btn"
            @click="singpassLogin"
          >
            Yes, continue
          </b-button>
          <b-button
            variant="link"
            class="p4b__onboard-retrieve-cancel-btn"
            @click="$router.go(-1)"
          >
            Cancel
          </b-button>
        </div>
      </div>

      <div
        v-if="isMobile()"
        class="p4b__onboard-retrieve-singpass__bottom"
      >
        <div
          class="p4b__onboard-retrieve-desktop-btn"
        >
          <b-button
            pill
            class="p4b__onboard-retrieve-continue-btn"
          >
            Yes, continue
          </b-button>
          <b-button
            variant="link"
            class="p4b__onboard-retrieve-cancel-btn"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from '../Loader.vue'

export default {
  components: {
    BButton,
    BImg,
    Loader,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-blue.png'),
      // eslint-disable-next-line global-require
      singPassLogo: require('@/assets/images/frontend/p4b/singpass-logo-large.png'),
      tokenDetail: {},
    }
  },

  beforeMount() {
    document.body.classList.add('p4b__bg')
  },
  methods: {
    singpassLogin() {
      const formData = new FormData()
      formData.append('purpose', 'onboarding')

      this.$http.post('front-end/singpass/login', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          localStorage.setItem('singpassAuthorizationSession', response.data.sessionID)
          window.location.href = response.data.authorizationUrl
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
