<template>
  <div class="p4b__parent">
    <div
      v-if="showMessage"
      class="container"
    >
      <div class="p4b__toc-content">
        <div class="p4b__toc-logo">
          <img
            :src="isMobile() ? logoMobImg : logoImg"
            alt="Logo"
          >
        </div>
        <b-card>
          <b-card-text class="p4b__toc-text-block">
            <h4>Terms of joining</h4>
            <p>By joining Nirvana Singapore, you agree to adhere to the terms and conditions outlined below. These terms are designed to ensure a fair and transparent relationship between you and the company. Your participation signifies your commitment to follow our policies, uphold the standards of conduct, and contribute positively to the community. Please review these terms carefully, as they govern your rights and obligations as a member.</p>
            <p>As an agent of Nirvana Singapore, you are expected to maintain accurate and up-to-date information in your profile. Failure to do so may result in restricted access or suspension of your account. Additionally, you agree to use the services provided by the company in compliance with all applicable laws and regulations. Any misuse of the platform, including but not limited to fraud, harassment, or unauthorised distribution of content, will lead to immediate termination of your membership.</p>
          </b-card-text>

          <b-card-text class="p4b__toc-text-block p4b__toc-text-mt">
            <b-form-checkbox
              v-model="agreeWithToc"
              class="p4b__checkbox-input"
            >
              I agree to Nirvana’s Terms of joining.
            </b-form-checkbox>
          </b-card-text>
        </b-card>
      </div>
    </div>

    <div
      v-if="showMessage"
      class="p4b__btn-section"
    >
      <b-button
        class="p4b__btn-common"
        :disabled="!agreeWithToc || !showMessage"
        block
        :to="{ name: 'onboard-method-frontend', params: { token: $route.params.token } }"
      >
        Let’s get started
      </b-button>
    </div>

    <div v-if="expiredLink">
      <div class="container">
        <div class="p4b__onboarding-form-content">
          <div class="p4b__toc-logo">
            <img
              :src="isMobile() ? logoWhiteMobImg : logoWhiteLargeImg"
              alt="Logo"
            >
          </div>
          <div class="p4b__onboarding-form-link-heading">
            <feather-icon
              icon="LinkIcon"
              size="80"
            />
            <h4>This invite has expired</h4>
            <p>Your onboarding link has expired. Please request a new link from your upline to continue the process.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, BCard, BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BFormCheckbox,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-blue.png'),
      // eslint-disable-next-line global-require
      logoMobImg: require('@/assets/images/frontend/p4b/logo-blue-mob.png'),
      // eslint-disable-next-line global-require
      logoWhiteLargeImg: require('@/assets/images/frontend/p4b/logo-white-large.png'),
      // eslint-disable-next-line global-require
      logoWhiteMobImg: require('@/assets/images/frontend/p4b/logo-white-mob.png'),
      agreeWithToc: false,
      expiredLink: false,
      showMessage: false,
      tokenDetail: {},
    }
  },

  beforeMount() {
    document.body.classList.add('p4b__bg')
    const { token } = this.$route.params
    if (token) {
      this.$http.get('front-end/agent/onboarding/validate/token', { params: { token } })
        .then(response => {
          if (response.data.success) {
            if (response.data.validToken) {
              this.showMessage = true
              this.tokenDetail = response.data.token || {}
            } else {
              document.body.classList.remove('p4b__bg')
              document.body.classList.add('p4b__bg-black')
              this.expiredLink = true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
}
</script>
