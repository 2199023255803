<template>
  <div>
    <template v-if="pastInstallments && pastInstallments.length">
      <b-card class="">
        <b-card-body class="pt-0">
          <b-tabs
            class="purchaser-tabs"
            content-class="mt-2"
          >
            <b-tab
              title="Current"
            >
              <b-card
                v-if="openInstallment"
                class="summary-card"
              >
                <b-row>
                  <b-col md="6">
                    <b-table-simple
                      borderless
                      responsive
                      class="particulars_of_deceased d-none d-md-block "
                    >
                      <b-tbody>
                        <b-tr>
                          <b-th width="230px">
                            Account No.
                          </b-th>
                          <b-td>{{ installmentSummary.accountNo ? installmentSummary.accountNo : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Approved Date
                          </b-th>
                          <b-td>{{ installmentSummary.approvedDate ? dateFormat(installmentSummary.approvedDate) : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Disbursement Date
                          </b-th>
                          <b-td>{{ installmentSummary.disbursementDate ? dateFormat(installmentSummary.disbursementDate) : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            First Due Date
                          </b-th>
                          <b-td>{{ (installmentData && installmentData.length && installmentData[0].dueDate) ? dateFormat(installmentData[0].dueDate) : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Loan Amount
                          </b-th>
                          <b-td><strong>{{ installmentSummary.loanAmount ? `$${numberFormat(installmentSummary.loanAmount)}` : '-' }}</strong></b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Tenure
                          </b-th>
                          <b-td>{{ installmentSummary ? installmentSummary.tenure : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Payment Method
                          </b-th>
                          <b-td>{{ ledgerSummary.paymentMethod ? ledgerSummary.paymentMethod : '-' }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                  <b-col md="6">
                    <b-table-simple
                      borderless
                      responsive
                      class="particulars_of_deceased d-none d-md-block "
                    >
                      <b-tbody>
                        <b-tr>
                          <b-th width="230px">
                            Monthly Installment
                          </b-th>
                          <b-td>{{ installmentSummary.monthlyAmount ? `$${numberFormat(installmentSummary.monthlyAmount)}` : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Final Installment
                          </b-th>
                          <b-td>{{ installmentSummary.finalAmount ? `$${numberFormat(installmentSummary.finalAmount)}` : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Status
                          </b-th>
                          <b-td>
                            <span class="text-success">Open</span>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>

                    <hr>

                    <div>
                      <h5 class="font-weight-bolder">
                        Remarks
                      </h5>
                      <b-row>
                        <b-col
                          class="content-header-left"
                          cols="12"
                          md="12"
                        >
                          <p>{{ installmentSummary.remarks ? installmentSummary.remarks : '-' }}</p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-card>

              <b-table
                v-if="openInstallment"
                ref="refInstallmentScheduleTable"
                class="position-relative event_list shadow-table"
                :items="installmentData"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                no-sort-reset
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(dueDate)="data">
                  <div class="text-nowrap">
                    <span class="">{{ dateFormat(data.item.dueDate) }}</span>
                  </div>
                </template>
                <template #cell(principleAmount)="data">
                  <div class="text-nowrap">
                    <span class="">{{ numberFormat(data.item.principleAmount) }}</span>
                  </div>
                </template>
                <template #cell(lateAmount)="data">
                  <div class="text-nowrap">
                    <span class="">{{ numberFormat(data.item.lateAmount) }}</span>
                  </div>
                </template>
                <!-- Column: Status -->
                <template #cell(paymentStatus)="data">
                  <b-badge
                    :variant="`light-${resolvePaymentStatusVariant(data.item.paymentStatus)}`"
                    class="text-capitalize"
                  >
                    {{ data.item.paymentStatus || '-' }}
                  </b-badge>
                </template>
                <template #bottom-row="">
                  <td
                    class=""
                    colspan="6"
                  >
                    <b-row>
                      <b-col
                        md="2"
                        sm="2"
                        class="my-25"
                      >
                        <b-form-group
                          class="mb-0"
                        >
                          <label class="d-inline-block text-sm-left mr-50 weight-400 fs-14">Per page</label>
                          <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            class="w-50"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="10"
                        sm="10"
                        class="my-25"
                      >
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          first-number
                          last-number
                          class="mb-0 mt-1 mt-sm-0"
                          align="right"
                          prev-text="Prev"
                          next-text="Next"
                        />
                      </b-col>
                    </b-row>
                  </td>
                </template>
              </b-table>
            </b-tab>
            <b-tab
              title="Closed"
            >
              <b-card
                v-for="(pastInstallmentSummary, index) in pastInstallments"
                :key="'past-summary' + index"
                class="summary-card"
              >
                <b-row>
                  <b-col md="6">
                    <b-table-simple
                      borderless
                      responsive
                      class="particulars_of_deceased d-none d-md-block "
                    >
                      <b-tbody>
                        <b-tr>
                          <b-th width="230px">
                            Account No.
                          </b-th>
                          <b-td>{{ pastInstallmentSummary.accountNo ? pastInstallmentSummary.accountNo : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Approved Date
                          </b-th>
                          <b-td>{{ pastInstallmentSummary.approvedDate ? dateFormat(pastInstallmentSummary.approvedDate) : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Disbursement Date
                          </b-th>
                          <b-td>{{ pastInstallmentSummary.disbursementDate ? dateFormat(pastInstallmentSummary.disbursementDate) : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            First Due Date
                          </b-th>
                          <b-td>{{ (installmentData && installmentData.length && installmentData[0].dueDate) ? dateFormat(installmentData[0].dueDate) : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Loan Amount
                          </b-th>
                          <b-td><strong>{{ pastInstallmentSummary.loanAmount ? `$${numberFormat(pastInstallmentSummary.loanAmount)}` : '-' }}</strong></b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Tenure
                          </b-th>
                          <b-td>{{ pastInstallmentSummary ? pastInstallmentSummary.tenure : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Payment Method
                          </b-th>
                          <b-td>{{ ledgerSummary.paymentMethod ? ledgerSummary.paymentMethod : '-' }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                  <b-col md="6">
                    <b-table-simple
                      borderless
                      responsive
                      class="particulars_of_deceased d-none d-md-block "
                    >
                      <b-tbody>
                        <b-tr>
                          <b-th width="230px">
                            Monthly Installment
                          </b-th>
                          <b-td>{{ pastInstallmentSummary.monthlyAmount ? `$${numberFormat(pastInstallmentSummary.monthlyAmount)}` : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Final Installment
                          </b-th>
                          <b-td>{{ pastInstallmentSummary.finalAmount ? `$${numberFormat(pastInstallmentSummary.finalAmount)}` : '-' }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th width="230px">
                            Status
                          </b-th>
                          <b-td>
                            <span class="text-danger">Close</span>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>

                    <hr>

                    <div>
                      <h5 class="font-weight-bolder">
                        Remarks
                      </h5>
                      <b-row>
                        <b-col
                          class="content-header-left"
                          cols="12"
                          md="12"
                        >
                          <p>{{ pastInstallmentSummary.remarks ? pastInstallmentSummary.remarks : '-' }}</p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </template>
    <template v-else>
      <b-card class="summary-card mt-1">
        <b-row>
          <b-col md="6">
            <b-table-simple
              borderless
              responsive
              class="particulars_of_deceased d-none d-md-block "
            >
              <b-tbody>
                <b-tr>
                  <b-th width="230px">
                    Account No.
                  </b-th>
                  <b-td>{{ installmentSummary.accountNo ? installmentSummary.accountNo : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Approved Date
                  </b-th>
                  <b-td>{{ installmentSummary.approvedDate ? dateFormat(installmentSummary.approvedDate) : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Disbursement Date
                  </b-th>
                  <b-td>{{ installmentSummary.disbursementDate ? dateFormat(installmentSummary.disbursementDate) : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    First Due Date
                  </b-th>
                  <b-td>{{ (installmentData && installmentData.length && installmentData[0].dueDate) ? dateFormat(installmentData[0].dueDate) : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Loan Amount
                  </b-th>
                  <b-td><strong>{{ installmentSummary.loanAmount ? `$${numberFormat(installmentSummary.loanAmount)}` : '-' }}</strong></b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Tenure
                  </b-th>
                  <b-td>{{ installmentSummary ? installmentSummary.tenure : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Payment Method
                  </b-th>
                  <b-td>{{ ledgerSummary.paymentMethod ? ledgerSummary.paymentMethod : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
          <b-col md="6">
            <b-table-simple
              borderless
              responsive
              class="particulars_of_deceased d-none d-md-block "
            >
              <b-tbody>
                <b-tr>
                  <b-th width="230px">
                    Monthly Installment
                  </b-th>
                  <b-td>{{ installmentSummary.monthlyAmount ? `$${numberFormat(installmentSummary.monthlyAmount)}` : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Final Installment
                  </b-th>
                  <b-td>{{ installmentSummary.finalAmount ? `$${numberFormat(installmentSummary.finalAmount)}` : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <hr>

            <div>
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <b-row>
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <p>{{ installmentSummary.remarks ? installmentSummary.remarks : '-' }}</p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-table
        ref="refInstallmentScheduleTable"
        class="position-relative event_list shadow-table"
        :items="installmentData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(dueDate)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormat(data.item.dueDate) }}</span>
          </div>
        </template>
        <template #cell(principleAmount)="data">
          <div class="text-nowrap">
            <span class="">{{ numberFormat(data.item.principleAmount) }}</span>
          </div>
        </template>
        <template #cell(lateAmount)="data">
          <div class="text-nowrap">
            <span class="">{{ numberFormat(data.item.lateAmount) }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(paymentStatus)="data">
          <b-badge
            :variant="`light-${resolvePaymentStatusVariant(data.item.paymentStatus)}`"
            class="text-capitalize"
          >
            {{ data.item.paymentStatus || '-' }}
          </b-badge>
        </template>
        <template #bottom-row="">
          <td
            class=""
            colspan="6"
          >
            <b-row>
              <b-col
                md="2"
                sm="2"
                class="my-25"
              >
                <b-form-group
                  class="mb-0"
                >
                  <label class="d-inline-block text-sm-left mr-50 weight-400 fs-14">Per page</label>
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="10"
                sm="10"
                class="my-25"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  align="right"
                  prev-text="Prev"
                  next-text="Next"
                />
              </b-col>
            </b-row>
          </td>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import {
  BCard, BTable, VBTooltip, BRow, BCol, BBadge, BTableSimple, BTbody, BTr, BTh, BTd, BPagination, BFormGroup, BFormSelect,
  BCardBody, BTabs, BTab,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BRow,
    BCol,
    BPagination,
    BFormGroup,
    BFormSelect,
    BCardBody,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    installmentData: {
      type: [Array, null],
      default: () => [],
    },
    installmentSummary: {
      type: [Object, null],
      default: () => {},
    },
    ledgerSummary: {
      type: [Object, null],
      default: () => {},
    },
    pastInstallments: {
      type: [Array, null],
      default: () => [],
    },
    openInstallment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'sequence',
      sortDesc: false,
      isSortDirDesc: false,
      tableItems: [],
      currentPage: 1,
      totalRows: this.installmentData.length,
      perPage: 15,
      pageOptions: [10, 15, 20],
      tableColumns: [
        {
          key: 'sequence',
          label: 'Installment #',
          sortable: true,
        },
        {
          key: 'dueDate',
          label: 'Due Date',
          sortable: true,
        },
        {
          key: 'principleAmount',
          label: 'Principal Amount',
          sortable: true,
        },
        {
          key: 'lateAmount',
          label: 'Late Fee',
          sortable: true,
        },
        {
          key: 'paymentStatus',
          label: 'Payment Status',
          sortable: true,
        },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolvePaymentStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Overdue') return 'danger'
      if (status === 'Upcoming') return 'info'
      if (status === 'Unpaid') return 'danger'
      return 'primary'
    },
  },
}
</script>
