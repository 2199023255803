<template>
  <div class="p4b__parent p4b__pb-120">
    <div
      v-show="$store.state.appConfig.showLoader && !showPayNow"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="container">
      <div
        v-if="isMobile()"
        class="p4b__mobile-back-btn"
      >
        <b-button
          variant="flat"
          class="btn-icon"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="24"
            icon="ArrowLeftIcon"
          />
        </b-button>
      </div>
      <div class="p4b__toc-logo">
        <img
          :src="logoImg"
          alt="Logo"
        >
      </div>
      <div
        v-if="showPayNow"
        class="p4b__payNow-wrapper"
      >
        <b-card class="p4b__payNow-card">
          <b-card-text class="p4b__payNow-card__content">
            <b-img
              :src="payNowTypoLogo"
              alt="payNow logo"
              class="p4b__payNow-logo"
            />
            <h4>Scan the QR code to pay</h4>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#6A5E32"
                stroke-linecap="round"
                stroke-linejoin="round"
                width="24"
                height="24"
                stroke-width="2"
              >
                <path d="M5 13a7 7 0 1 0 14 0a7 7 0 0 0 -14 0z" /> <path d="M14.5 10.5l-2.5 2.5" /> <path d="M17 8l1 -1" /> <path d="M14 3h-4" />
              </svg>
              <!-- <span>4min:08s</span> -->
              <span>{{ timeRemaining }}</span>
            </p>
            <div class="p4b__payNow-card-qr-code">
              <b-img
                :src="payNowQRCode"
                alt="payNow logo"
              />
            </div>
            <b-button
              class="p4b__payNow-btn__outline"
              @click="saveQrCode"
            >
              Save QR Code
            </b-button>
            <h3>${{ numberFormat(totalPayable) }}</h3>
          </b-card-text>
        </b-card>

        <div class="p4b__payNow-bottom-section">
          <h4>Please follow this instructions</h4>
          <div class="p4b__payNow-single-instruction">
            <span class="p4b__payNow-serial-circle"><span>1</span></span>
            <div class="p4b__payNow-serial-text">
              <p><strong>Save or screenshot the QR code</strong> to make a payment using any PayNow- supported app.</p>
            </div>
          </div>
          <div class="p4b__payNow-single-instruction">
            <span class="p4b__payNow-serial-circle"><span>2</span></span>
            <div class="p4b__payNow-serial-text">
              <p>Ensure the recipient is <strong>Nirvana Memorial Garden Pte Ltd.</strong></p>
            </div>
          </div>
        </div>
      </div>
      <b-row v-if="showPayment">
        <b-col md="7">
          <h2 class="p4b__payment-review-title">
            Review payment
          </h2>
          <b-card>
            <b-card-text class="p4b__payment-review-list">
              <b-table-simple
                responsive
                caption-top
                class="p4b__payment-review-table"
              >
                <caption>
                  Summary
                </caption>
                <b-tbody>
                  <b-tr
                    v-for="(item, index) in selectedOtherFees"
                    :key="'rf' + index"
                  >
                    <b-td width="75%">
                      Reactivation fee
                    </b-td>
                    <b-td class="text-right">
                      ${{ numberFormat(item.allocated) }}
                    </b-td>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in selectedInstallments"
                    :key="'ins' + index"
                  >
                    <b-td width="75%">
                      Installment #{{ item.sequence }}
                    </b-td>
                    <b-td class="text-right">
                      ${{ numberFormat(item.allocated) }}
                    </b-td>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in selectedInvoices"
                    :key="'inv' + index"
                  >
                    <b-td width="75%">
                      Invoice {{ item.trxNo }}
                    </b-td>
                    <b-td class="text-right">
                      ${{ numberFormat(item.allocated) }}
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td>
                      Total
                    </b-td>
                    <b-td class="text-right">
                      ${{ numberFormat(totalPayable) }}
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="5">
          <b-card class="p4b__payment-mode-card">
            <b-card-text>
              <h4>Select your preferred payment mode</h4>
              <div class="p4b__payment-mode-btn__wrapper">
                <b-button
                  block
                  variant="flat"
                  class="p4b__payment-mode-btn"
                  @click="paymentMethod = 'Card'"
                >
                  <div>
                    <b-img
                      :src="visaCard"
                      alt="visa"
                      class="mr-50"
                    />
                    <b-img
                      :src="masterCard"
                      alt="master"
                    />
                  </div>
                  <span>Credit Card</span>
                </b-button>
                <b-button
                  block
                  variant="flat"
                  class="p4b__payment-mode-btn"
                  @click="paymentMethod = 'GrabPay'"
                >
                  <b-img
                    :src="grabPay"
                    alt="GrabPay"
                  />
                  <span>grabPay</span>
                </b-button>
                <b-button
                  block
                  variant="flat"
                  class="p4b__payment-mode-btn"
                  @click="paymentMethod = 'PayNow'"
                >
                  <div>
                    <b-img
                      :src="payNow"
                      alt="payNow"
                    />
                  </div>
                  <span>payNow</span>
                </b-button>
                <!-- <b-button
                block
                variant="flat"
                class="p4b__payment-mode-btn"
              >
                <div>
                  <b-img
                    :src="googlePay"
                    alt="googlePay"
                  />
                </div>
                <span>googlePay</span>
              </b-button> -->
                <div
                  v-if="loadGooglePay"
                  class="p4b__payment-mode-btn mt-md-50"
                >
                  <google-pay-button
                    class="google-pay-button"
                    environment="TEST"
                    :button-color="buttonColor"
                    :button-type="buttonType"
                    :button-radius="buttonRadius"
                    :button-size-mode="isCustomSize ? 'fill' : 'static'"
                    :payment-request.prop="paymentRequest"
                    @loadpaymentdata="onLoadPaymentData"
                    @error="onError"
                  />
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="showPayment"
      class="p4b__btn-section"
    >
      <b-button
        class="p4b__payment-btn-common"
        block
        :disabled="totalPayable <= 0 || paymentMethod === ''"
        @click="processPayment"
      >
        <feather-icon
          icon="DollarSignIcon"
          size="14"
          style="vertical-align: middle;"
        />
        {{ paymentButtonText }}
      </b-button>
      <div class="p4b__payment-terms">
        <p>By proceeding with payment, you agree to Nirvana SG’s
          <a href="#">Privacy Policy</a> and <a href="#">Refund Policy</a></p>
      </div>
    </div>

    <b-modal
      id="modal-payment-success"
      ref="payment-success-modal"
      modal-class="p4b__payment-status__modal"
      body-class="reduce-padding-0"
      size="470"
      centered
      no-stacking
      no-close-on-backdrop
      hide-footer
      header-class="front-modal"
    >
      <div class="p4b__payment-modal-icon">
        <b-avatar
          class="p4b__payment-modal-avatar-success"
          size="83.33px"
        >
          <span class="d-flex align-items-center">
            <feather-icon
              icon="CheckIcon"
              size="50"
            />
          </span>
        </b-avatar>
      </div>
      <div
        v-if="receipt && receipt.trxNo"
        class="p4b__payment-modal-content"
      >
        <h3>Payment successful</h3>
        <p>Thank you for the payment. Your receipt will be sent to your email <strong>{{ email }}.</strong></p>
        <b-button
          class="p4b__payment-btn-common"
          :to="{ name: 'file-payment-frontend-receipt-view', params: { id: receipt._id } }"
        >
          <b-img
            :src="noteIcon"
            alt="note-icon"
          />
          View receipt
        </b-button>
        <div class="p4b__modal-success-copy-text">
          <p>Receipt number: <strong>{{ receipt.trxNo }}</strong></p>
          <feather-icon
            icon="CopyIcon"
            height="22"
            width="19"
            cursor="pointer"
            color="#D6C691"
            @click="copyToClipboard(receipt._id)"
          />
        </div>
      </div>
      <div
        v-else
        class="p4b__payment-modal-content"
      >
        <h3>Payment successful</h3>
        <p>Thank you for the payment. Your receipt will be sent to your email <strong>{{ email }}.</strong></p>
      </div>
      <div class="clear" />
    </b-modal>

    <b-modal
      id="modal-payment-failure"
      ref="payment-failure-modal"
      modal-class="p4b__payment-status__modal"
      body-class="reduce-padding-0"
      size="470"
      centered
      no-stacking
      no-close-on-backdrop
      hide-footer
      header-class="front-modal"
    >
      <div class="p4b__payment-modal-icon">
        <b-avatar
          class="p4b__payment-modal-avatar-danger"
          size="83.33px"
        >
          <span class="d-flex align-items-center">
            <feather-icon
              icon="XIcon"
              size="50"
            />
          </span>
        </b-avatar>
      </div>
      <div class="p4b__payment-modal-content">
        <h3>Payment failed</h3>
        <p>Payment was unsuccessful. Your credit card was not charged. Please select other payment mode or try again.</p>
        <b-button
          class="p4b__payment-btn-common"
          @click="closeModal"
        >
          Try again
        </b-button>
        <b-button
          class="p4b__payment-modal-link-btn"
          variant="link"
          @click="closeModal"
        >
          Back to review payment
        </b-button>
      </div>
      <div class="clear" />
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BCard, BCardText, BRow, BCol, BTableSimple, BTbody, BTfoot, BTr, BTd, BImg, BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from '../Loader.vue'
import '@google-pay/button-element'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTfoot,
    BTr,
    BTd,
    BImg,
    BAvatar,

    Loader,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      noteIcon: require('@/assets/images/frontend/p4b/icons/note.png'),
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-yellow.png'),
      // eslint-disable-next-line global-require
      visaCard: require('@/assets/images/frontend/p4b/payment-logo/visa.png'),
      // eslint-disable-next-line global-require
      masterCard: require('@/assets/images/frontend/p4b/payment-logo/mastercard.png'),
      // eslint-disable-next-line global-require
      grabPay: require('@/assets/images/frontend/p4b/payment-logo/grab-pay.png'),
      // eslint-disable-next-line global-require
      payNow: require('@/assets/images/frontend/p4b/payment-logo/pay-now.png'),
      // eslint-disable-next-line global-require
      googlePay: require('@/assets/images/frontend/p4b/payment-logo/google-pay.png'),
      // eslint-disable-next-line global-require
      payNowTypoLogo: require('@/assets/images/frontend/p4b/paynow-logo.png'),
      totalPayable: 0,
      selectedOtherFees: [],
      selectedInstallments: [],
      selectedInvoices: [],
      paymentMethod: '',
      fileDetail: {},
      tokenDetail: {},
      showPayNow: false,
      payNowQRCode: '',
      showPayment: false,
      omiseToken: '',
      loadGooglePay: false,
      email: '',
      receipt: {},
      timeCountDown: 300,
      timer: null,

      buttonColor: 'white',
      buttonType: 'plain',
      buttonRadius: 0,
      isCustomSize: true,
      buttonWidth: 240,
      buttonHeight: 40,
      isTop: window === window.top,
      paymentRequest: {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY'],
              allowedCardNetworks: ['AMEX', 'VISA', 'MASTERCARD'],
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'omise',
                gatewayMerchantId: '',
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: '12345678901234567890',
          merchantName: 'Demo Merchant',
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: '1.00',
          currencyCode: 'SGD',
          countryCode: 'SG',
        },
      },
    }
  },
  computed: {
    paymentButtonText() {
      const length = this.selectedOtherFees.length + this.selectedInstallments.length + this.selectedInvoices.length
      if (length > 1) {
        return `Pay ${length} items ($${this.numberFormat(this.totalPayable)})`
      }
      if (length === 1) {
        return `Pay ${length} item ($${this.numberFormat(this.totalPayable)})`
      }
      return 'Pay'
    },
    timeRemaining() {
      const minutes = Math.floor(this.timeCountDown / 60)
      const seconds = this.timeCountDown % 60
      return `${minutes}min:${seconds < 10 ? '0' : ''}${seconds}s`
    },
  },

  beforeMount() {
    document.body.classList.add('p4b__payment-bg')
    const { token } = this.$route.params
    if (token) {
      this.$http.get('front-end/payment/validate/token', { params: { token } })
        .then(response => {
          if (response.data.success) {
            if (response.data.validToken) {
              this.showPayment = true
              this.omiseToken = response.data.omiseToken
              this.paymentRequest.allowedPaymentMethods[0].tokenizationSpecification.parameters.gatewayMerchantId = this.omiseToken
              const paymentSelectionDetail = JSON.parse(localStorage.getItem('paymentSelectionDetail'))
              this.totalPayable = paymentSelectionDetail.totalPayable || 0
              this.paymentRequest.transactionInfo.totalPrice = this.numberFormat(this.totalPayable)
              this.selectedOtherFees = paymentSelectionDetail.selectedOtherFees || []
              this.selectedInstallments = paymentSelectionDetail.selectedInstallments || []
              this.selectedInvoices = paymentSelectionDetail.selectedInvoices || []
              this.fileDetail = paymentSelectionDetail.fileDetail || {}
              this.tokenDetail = paymentSelectionDetail.tokenDetail || {}
              // eslint-disable-next-line no-undef
              Omise.setPublicKey(this.omiseToken)
              this.loadGooglePay = true
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },

  methods: {
    async onLoadPaymentData(event) {
      const { token } = event.detail.paymentMethodData.tokenizationData
      const tokenParameters = {
        method: 'googlepay',
        data: token,
      }
      const vm = this
      // eslint-disable-next-line no-undef
      Omise.createToken('tokenization', tokenParameters, (statusCode, response) => {
        if (statusCode === 200) {
          vm.processGooglePay(response.id)
        }
      })
    },
    onError: event => {
      console.error('error', event.error)
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'modal-payment-failure', '')
    },
    processGooglePay(token) {
      const formData = new FormData()
      formData.append('url', window.location.origin)
      formData.append('token', this.$route.params.token)
      formData.append('paymentMethod', 'GooglePay')
      formData.append('cardToken', token)
      formData.append('cardNumber', '')
      formData.append('totalAmount', this.totalPayable)
      formData.append('fileID', this.fileDetail.fileID)
      formData.append('paidInstallments', JSON.stringify(this.selectedInstallments))
      formData.append('paidInvoices', JSON.stringify(this.selectedInvoices))
      formData.append('reactivationFees', JSON.stringify(this.selectedOtherFees))

      this.$http.post('front-end/payment/frontend/card/charge', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async res => {
          if (res.data.success) {
            this.showPayment = false
            this.email = res.data.email
            this.receipt = res.data.receipt || {}
            localStorage.setItem('customerReceiptDetail', JSON.stringify(this.receipt))
            await this.$nextTick()
            this.$root.$emit('bv::show::modal', 'modal-payment-success', '')
          } else {
            this.$root.$emit('bv::show::modal', 'modal-payment-failure', '')
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    processPayment() {
      if (this.paymentMethod === 'Card') {
        this.$router.push({ name: 'file-payment-frontend-card', params: { token: this.$route.params.token } })
      } else if (this.paymentMethod === 'GooglePay') {
        // do google pay payment
      } else {
        let omisePaymentMethod = ''
        if (this.paymentMethod === 'GrabPay') {
          omisePaymentMethod = 'grabpay'
        } else if (this.paymentMethod === 'PayNow') {
          omisePaymentMethod = 'paynow'
        }
        const formData = new FormData()
        formData.append('url', window.location.origin)
        formData.append('token', this.$route.params.token)
        formData.append('paymentMethod', this.paymentMethod)
        formData.append('paymentMode', omisePaymentMethod)
        formData.append('totalAmount', this.totalPayable)
        formData.append('fileID', this.fileDetail.fileID)
        formData.append('paidInstallments', JSON.stringify(this.selectedInstallments))
        formData.append('paidInvoices', JSON.stringify(this.selectedInvoices))
        formData.append('reactivationFees', JSON.stringify(this.selectedOtherFees))

        this.$http.post('front-end/payment/frontend/initiate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(res => {
            if (res.data.success) {
              if (res.data.redirectUrl) {
                window.open(res.data.redirectUrl, '_self')
              }
              if (res.data.image) {
                this.timeCountDown = 300
                this.countDownTimer()
                this.payNowQRCode = res.data.image
                this.showPayment = false
                this.showPayNow = true
                this.timer = setInterval(() => {
                  this.checkForPaymentStatus()
                }, 10000)
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    async copyToClipboard(id) {
      try {
        const text = `${window.location.origin}/receipt/${id}/view`
        await navigator.clipboard.writeText(text)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Receipt has been copied',
            icon: 'BellIcon',
          },
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      }
    },
    checkForPaymentStatus() {
      this.$http.get(`front-end/payment/token/${this.tokenDetail._id}/check/status`)
        .then(async response => {
          if (response.data.success) {
            clearInterval(this.timer)
            this.email = response.data.email
            this.receipt = response.data.receipt || {}
            await this.$nextTick()
            this.$root.$emit('bv::show::modal', 'modal-payment-success', '')
            this.showPayNow = false
          }

          if (response.data.failed) {
            clearInterval(this.timer)
            this.showPayNow = false
            this.showPayment = true
            this.$root.$emit('bv::show::modal', 'modal-payment-failure', '')
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    regenerateQRCode() {
      const formData = new FormData()
      formData.append('url', window.location.origin)
      formData.append('token', this.$route.params.token)
      formData.append('totalAmount', this.totalPayable)
      formData.append('paymentMethod', this.paymentMethod)
      formData.append('paymentMode', 'paynow')
      this.$http.post('front-end/payment/frontend/pay-now/regenerate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(res => {
          if (res.data.success) {
            if (res.data.image) {
              this.timeCountDown = 300
              this.countDownTimer()
              this.payNowQRCode = res.data.image
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    countDownTimer() {
      if (this.timeCountDown > 0) {
        setTimeout(() => {
          this.timeCountDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.regenerateQRCode()
      }
    },
    saveQrCode() {
      const downloadImage = document.createElement('a')
      document.body.appendChild(downloadImage)
      downloadImage.setAttribute('download', 'image')
      downloadImage.href = this.payNowQRCode
      downloadImage.click()
      downloadImage.remove()
    },
  },
}
</script>
