<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Contact Information
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <b-button
          v-if="canViewThisAction('update', 'ContactInformation')"
          variant="flat-primary"
          class="ml-auto bg-white"
          :to="{ name: 'app-contact-information-edit' }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana SG</strong> This is the control panel where administrators can edit the content for the customer-facing mobile app.</span>
      </div>
    </b-alert>

    <b-card
      no-body
      class="mb-3 p-2"
    >
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Operating Hours
            </b-th>
            <b-td>{{ settings.operatingHours || '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Contact Number
            </b-th>
            <b-td>{{ settings.contactNumber || '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Email Contact
            </b-th>
            <b-td>{{ settings.contactEmail || '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Address
            </b-th>
            <b-td>{{ settings.address || '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Latitude
            </b-th>
            <b-td>{{ settings.latitude || '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Longitude
            </b-th>
            <b-td>{{ settings.longitude || '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BAlert,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BAlert,
  },
  data() {
    return {
      canViewThisAction,
      settings: {},
    }
  },
  created() {
    this.$http.get('directory/app/contact-information/show')
      .then(response => {
        this.settings = response.data.data || {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>
