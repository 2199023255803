<template>
  <div class="p4b__parent p4b__pb-40">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="container">
      <div class="p4b__toc-logo">
        <img
          :src="logoImg"
          alt="Logo"
        >
      </div>
      <b-card
        v-if="showPayment"
        class="p4b__make-payment-card"
      >
        <b-card-text class="p4b__make-payment-card__content">
          <h4>Make Payment for #{{ fileDetail.fileNo }}</h4>
          <p>Please take a moment to complete your payment for your installments  and reactivation fees. The link is valid for a limited time, so kindly ensure you make the payment before it expires.</p>
          <p>This link is specifically for <strong>{{ fileDetail.nvsFileType }} {{ fileDetail.lotLocation }} (File no. {{ fileDetail.fileNo }}).</strong> Thank you!</p>
          <p>This payment link will expire on <span>{{ dateFormatWithFormatParam(tokenDetail.expiresAt, 'DD MMM YYYY, hh:mma') }}</span>.</p>
          <b-button
            class="p4b__payment-btn-common"
            block
            :to="{ name: 'file-payment-frontend', params: { token: $route.params.token } }"
          >
            <feather-icon
              icon="ArrowRightIcon"
              size="12"
              style="vertical-align: middle;"
            />
            Let’s get started
          </b-button>
        </b-card-text>
      </b-card>
      <div class="p4b__make-payment-logo">
        <p> <feather-icon
          icon="ShieldIcon"
          size="14"
        /> Secure payment</p>
        <div class="p4b__logo-wrapper">
          <b-img
            :src="visaCard"
            height="10.32px"
            alt="visaCard"
          />
          <b-img
            :src="masterCard"
            height="20px"
            alt="masterCard"
          />
          <b-img
            :src="payNow"
            height="18px"
            alt="payNow"
          />
          <b-img
            :src="googlePay"
            height="22px"
            alt="googlePay"
          />
          <b-img
            :src="grabPay"
            height="20px"
            alt="grabPay"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, BCard, BCardText, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from '../Loader.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BImg,

    Loader,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-yellow.png'),
      // eslint-disable-next-line global-require
      visaCard: require('@/assets/images/frontend/p4b/payment-logo/visa.png'),
      // eslint-disable-next-line global-require
      masterCard: require('@/assets/images/frontend/p4b/payment-logo/mastercard.png'),
      // eslint-disable-next-line global-require
      grabPay: require('@/assets/images/frontend/p4b/payment-logo/grab-pay.png'),
      // eslint-disable-next-line global-require
      payNow: require('@/assets/images/frontend/p4b/payment-logo/pay-now.png'),
      // eslint-disable-next-line global-require
      googlePay: require('@/assets/images/frontend/p4b/payment-logo/google-pay.png'),
      agreeWithToc: false,
      showPayment: false,
      fileDetail: {},
      tokenDetail: {},
    }
  },

  beforeMount() {
    document.body.classList.add('p4b__payment-bg')
    const { token } = this.$route.params
    if (token) {
      this.$http.get('front-end/payment/validate/token', { params: { token } })
        .then(response => {
          if (response.data.success) {
            if (response.data.validToken) {
              this.showPayment = true
              this.fileDetail = response.data.file || {}
              this.tokenDetail = response.data.token || {}
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
}
</script>
