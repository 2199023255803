<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1 class="custom-header-title">
                  Editing {{ customerDetail.stringID }} - {{ customerDetail.name }}
                </h1>
                <p class="log_info">
                  Created by {{ customerDetail.createdBy || '' }} on {{ customerDetail.createdAt ? dateFormatWithTime(customerDetail.createdAt) : '-' }}<br>Last updated on {{ customerDetail.updatedAt ? dateFormatWithTime(customerDetail.updatedAt) : '-' }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitSaveFormConfirmation">
      <validation-observer
        ref="customerEditForm"
      >
        <b-card
          v-if="canViewThisAction('update-personal-info', 'Customer')"
          ref="deceased_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Personal Information
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Name*"
                label-for="h-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-name"
                    v-model="name"
                    placeholder="Name"
                    class="text-uppercase"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="NRIC*"
                label-for="h-NRIC"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NRIC"
                  vid="NRIC"
                  rules="required"
                >
                  <b-form-input
                    id="h-NRIC"
                    v-model="NRIC"
                    placeholder="NRIC"
                    :state="(errors.length > 0 || NRICValidation) ? false : null"
                    name="NRIC"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="NRICValidation"
                    class="text-danger"
                  >
                    {{ NRICError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Date of Birth*"
                label-for="h-dob"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Date of Birth"
                  vid="dob"
                  rules="required"
                >
                  <b-input-group>
                    <flat-pickr
                      id="h-dob"
                      v-model="dob"
                      class="form-control flat-pickr-group"
                      placeholder="Date of Birth"
                      :config="flatPickrConfig"
                      @input="dobValidation == true ? dobValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dobValidation"
                    class="text-danger"
                  >
                    {{ dobError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Sex"
                vid="sex"
                rules="required"
              >
                <b-form-group
                  label="Sex*"
                  label-for="h-sex"
                  label-cols-md="4"
                  :state="(errors.length > 0 || sexValidation) ? false : null"
                >
                  <v-select
                    id="h-sex"
                    v-model="sex"
                    label="title"
                    :options="genderOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="sexValidation == true ? sexValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="sexValidation"
                    class="text-danger"
                  >
                    {{ sexError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Race"
                vid="race"
                rules="required"
              >
                <b-form-group
                  label="Race*"
                  label-for="h-race"
                  label-cols-md="4"
                  :state="(errors.length > 0 || raceValidation) ? false : null"
                >
                  <v-select
                    id="h-race"
                    v-model="race"
                    label="title"
                    :options="raceOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="raceValidation == true ? raceValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="raceValidation"
                    class="text-danger"
                  >
                    {{ raceError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <!-- <b-form-group
                label="Race*"
                label-for="h-race"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Race"
                  vid="race"
                  rules="required"
                >
                  <b-form-input
                    id="h-race"
                    v-model="race"
                    placeholder="Race"
                    :state="(errors.length > 0 || raceValidation) ? false : null"
                    name="Race"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="raceValidation"
                    class="text-danger"
                  >
                    {{ raceError }}
                  </small>
                </validation-provider>
              </b-form-group> -->
              <validation-provider
                #default="{ errors }"
                name="Nationality"
                vid="nationality"
                rules="required"
              >
                <b-form-group
                  label="Nationality*"
                  label-for="h-nationality"
                  label-cols-md="4"
                >
                  <v-select
                    id="h-nationality"
                    v-model="nationality"
                    label="title"
                    :options="nationalityOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="nationalityValidation == true ? nationalityValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nationalityValidation"
                    class="text-danger"
                  >
                    {{ nationalityError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Home Phone No*"
                label-for="h-home-phone-no"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Home Phone No"
                  vid="homePhoneNo"
                  rules="required"
                >
                  <b-form-input
                    id="h-home-phone-no"
                    v-model="homePhoneNo"
                    placeholder="Home Phone No"
                    :state="(errors.length > 0 || homePhoneNoValidation) ? false : null"
                    name="homePhoneNo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="homePhoneNoValidation"
                    class="text-danger"
                  >
                    {{ homePhoneNoError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Residential Address 1*"
                label-for="h-residential-address1"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Residential Address 1"
                  vid="residentialAddress1"
                  rules="required"
                >
                  <b-form-input
                    id="h-residential-address1"
                    v-model="residentialAddress1"
                    placeholder="Residential Address 1"
                    :state="(errors.length > 0 || residentialAddress1Validation) ? false : null"
                    name="residentialAddress1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="residentialAddress1Validation"
                    class="text-danger"
                  >
                    {{ residentialAddress1Error }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Residential Address 2*"
                label-for="h-residential-address2"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Residential Address 2"
                  vid="residentialAddress2"
                  rules="required"
                >
                  <b-form-input
                    id="h-residential-address2"
                    v-model="residentialAddress2"
                    placeholder="Residential Address 2"
                    :state="(errors.length > 0 || residentialAddress2Validation) ? false : null"
                    name="residentialAddress1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="residentialAddress2Validation"
                    class="text-danger"
                  >
                    {{ residentialAddress2Error }}
                  </small>
                </validation-provider>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Country"
                vid="country"
                rules="required"
              >
                <b-form-group
                  label="Country*"
                  label-for="h-country"
                  label-cols-md="4"
                >
                  <v-select
                    id="h-country"
                    v-model="country"
                    label="title"
                    :options="countryOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="countryValidation == true ? countryValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="countryValidation"
                    class="text-danger"
                  >
                    {{ countryError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <b-form-group
                label="Postal Code*"
                label-for="h-postal-code"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postal Code"
                  vid="postalCode"
                  rules="required"
                >
                  <b-form-input
                    id="h-postal-code"
                    v-model="postalCode"
                    placeholder="Postal Code"
                    :state="(errors.length > 0 || postalCodeValidation) ? false : null"
                    name="postalCode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="postalCodeValidation"
                    class="text-danger"
                  >
                    {{ postalCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          v-else
          ref="deceased_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Personal Information
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Name*"
                label-for="h-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules=""
                >
                  <b-form-input
                    id="h-name"
                    v-model="name"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    readonly
                    placeholder="Name"
                    class="text-uppercase"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                  />
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="NRIC*"
                label-for="h-NRIC"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NRIC"
                  vid="NRIC"
                  rules=""
                >
                  <b-form-input
                    id="h-NRIC"
                    v-model="NRIC"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    readonly
                    placeholder="NRIC"
                    :state="(errors.length > 0 || NRICValidation) ? false : null"
                    name="NRIC"
                  />
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Date of Birth*"
                label-for="h-dob"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ }"
                  name="Date of Birth"
                  vid="dob"
                  rules=""
                >
                  <b-input-group v-b-tooltip.hover.right="'You do not have the permission to edit'">
                    <b-form-input
                      id="h-dob"
                      v-model="disabledDob"
                      readonly
                      placeholder="Date of Birth"
                      name="dob"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Sex"
                vid="sex"
                rules=""
              >
                <b-form-group
                  label="Sex*"
                  label-for="h-sex"
                  label-cols-md="4"
                  :state="(errors.length > 0 || sexValidation) ? false : null"
                >
                  <v-select
                    id="h-sex"
                    v-model="sex"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    disabled
                    label="title"
                    :options="genderOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="sexValidation == true ? sexValidation = false : null"
                  />
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Race"
                vid="race"
                rules="required"
              >
                <b-form-group
                  label="Race*"
                  label-for="h-race"
                  label-cols-md="4"
                  :state="(errors.length > 0 || raceValidation) ? false : null"
                >
                  <v-select
                    id="h-race"
                    v-model="race"
                    label="title"
                    disabled
                    :options="raceOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="raceValidation == true ? raceValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="raceValidation"
                    class="text-danger"
                  >
                    {{ raceError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <!-- <b-form-group
                label="Race*"
                label-for="h-race"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Race"
                  vid="race"
                  rules=""
                >
                  <b-form-input
                    id="h-race"
                    v-model="race"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    readonly
                    placeholder="Race"
                    :state="(errors.length > 0 || raceValidation) ? false : null"
                    name="Race"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="raceValidation"
                    class="text-danger"
                  >
                    {{ raceError }}
                  </small>
                </validation-provider>
              </b-form-group> -->
              <validation-provider
                #default="{ errors }"
                name="Nationality"
                vid="nationality"
                rules=""
              >
                <b-form-group
                  label="Nationality*"
                  label-for="h-nationality"
                  label-cols-md="4"
                >
                  <v-select
                    id="h-nationality"
                    v-model="nationality"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    disabled
                    label="title"
                    :options="nationalityOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="nationalityValidation == true ? nationalityValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nationalityValidation"
                    class="text-danger"
                  >
                    {{ nationalityError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Home Phone No*"
                label-for="h-home-phone-no"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Home Phone No"
                  vid="homePhoneNo"
                  rules=""
                >
                  <b-form-input
                    id="h-home-phone-no"
                    v-model="homePhoneNo"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    readonly
                    placeholder="Home Phone No"
                    :state="(errors.length > 0 || homePhoneNoValidation) ? false : null"
                    name="homePhoneNo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="homePhoneNoValidation"
                    class="text-danger"
                  >
                    {{ homePhoneNoError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Residential Address 1*"
                label-for="h-residential-address1"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Residential Address 1"
                  vid="residentialAddress1"
                  rules=""
                >
                  <b-form-input
                    id="h-residential-address1"
                    v-model="residentialAddress1"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    readonly
                    placeholder="Residential Address 1"
                    :state="(errors.length > 0 || residentialAddress1Validation) ? false : null"
                    name="residentialAddress1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="residentialAddress1Validation"
                    class="text-danger"
                  >
                    {{ residentialAddress1Error }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Residential Address 2*"
                label-for="h-residential-address2"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Residential Address 2"
                  vid="residentialAddress2"
                  rules=""
                >
                  <b-form-input
                    id="h-residential-address2"
                    v-model="residentialAddress2"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    readonly
                    placeholder="Residential Address 2"
                    :state="(errors.length > 0 || residentialAddress2Validation) ? false : null"
                    name="residentialAddress1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="residentialAddress2Validation"
                    class="text-danger"
                  >
                    {{ residentialAddress2Error }}
                  </small>
                </validation-provider>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Country"
                vid="country"
                rules="required"
              >
                <b-form-group
                  label="Country*"
                  label-for="h-country"
                  label-cols-md="4"
                >
                  <v-select
                    id="h-country"
                    v-model="country"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    disabled
                    label="title"
                    :options="countryOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="countryValidation == true ? countryValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="countryValidation"
                    class="text-danger"
                  >
                    {{ countryError }}
                  </small>
                </b-form-group>
              </validation-provider>
              <b-form-group
                label="Postal Code*"
                label-for="h-postal-code"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postal Code"
                  vid="postalCode"
                  rules="required"
                >
                  <b-form-input
                    id="h-postal-code"
                    v-model="postalCode"
                    v-b-tooltip.hover.right="'You do not have the permission to edit'"
                    readonly
                    placeholder="Postal Code"
                    :state="(errors.length > 0 || postalCodeValidation) ? false : null"
                    name="postalCode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="postalCodeValidation"
                    class="text-danger"
                  >
                    {{ postalCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="agent_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Correspondence Contact
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Mobile No.*"
                label-for="h-mobile-no"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile No."
                  vid="mobileNo"
                  rules="required"
                >
                  <b-form-input
                    id="h-mobile-no"
                    v-model="mobileNo"
                    placeholder="Mobile No."
                    :state="(errors.length > 0 || mobileNoValidation) ? false : null"
                    name="mobileNo"
                    @input="mobileNoValidation == true ? mobileNoValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="mobileNoValidation"
                    class="text-danger"
                  >
                    {{ mobileNoError }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email Address*"
                label-for="h-email-address"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email Address"
                  vid="emailAddress"
                  rules="required|email"
                >
                  <b-form-input
                    id="h-email-address"
                    v-model="emailAddress"
                    placeholder="Email Address"
                    :state="(errors.length > 0 || emailAddressValidation) ? false : null"
                    name="emailAddress"
                    @input="emailAddressValidation == true ? emailAddressValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="emailAddressValidation"
                    class="text-danger"
                  >
                    {{ emailAddressError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6">
              <b-form-group>
                <b-form-checkbox
                  v-model="hasDifferentAddress"
                  value="Yes"
                  name="flavour-3a"
                  class="custom__input"
                >
                  Customer has a different mailing address
                </b-form-checkbox>
              </b-form-group>
              <div v-if="hasDifferentAddress">
                <b-form-group
                  label="Mailing Address 1"
                  label-for="h-address1"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing Address 1"
                    vid="mailingAddress1"
                    rules=""
                  >
                    <b-form-input
                      id="h-address1"
                      v-model="mailingAddress1"
                      placeholder="Mailing Address 1"
                      :state="(errors.length > 0 || mailingAddress1Validation) ? false : null"
                      name="mailingAddress1"
                      @input="mailingAddress1Validation == true ? mailingAddress1Validation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="mailingAddress1Validation"
                      class="text-danger"
                    >
                      {{ mailingAddress1Error }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Mailing Address 2"
                  label-for="h-address2"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing Address 2"
                    vid="mailingAddress2"
                    rules=""
                  >
                    <b-form-input
                      id="h-address2"
                      v-model="mailingAddress2"
                      placeholder="Mailing Address 2"
                      :state="(errors.length > 0 || mailingAddress2Validation) ? false : null"
                      name="mailingAddress2"
                      @input="mailingAddress2Validation == true ? mailingAddress2Validation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="mailingAddress2Validation"
                      class="text-danger"
                    >
                      {{ mailingAddress2Error }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  vid="cCountry"
                  rules=""
                >
                  <b-form-group
                    label="Country"
                    label-for="h-c-country"
                    label-cols-md="4"
                  >
                    <v-select
                      id="h-c-country"
                      v-model="cCountry"
                      label="title"
                      :options="countryOptions"
                      :reduce="title => title.code"
                      :clearable="false"
                      @input="cCountryValidation == true ? cCountryValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="cCountryValidation"
                      class="text-danger"
                    >
                      {{ cCountryError }}
                    </small>
                  </b-form-group>
                </validation-provider>
                <b-form-group
                  label="Postal Code"
                  label-for="h-post-code"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Postal Code"
                    vid="postCode"
                    rules=""
                  >
                    <b-form-input
                      id="h-post-code"
                      v-model="postCode"
                      placeholder="Postal Code"
                      :state="(errors.length > 0 || postCodeValidation) ? false : null"
                      name="postCode"
                      @input="postCodeValidation == true ? postCodeValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="postCodeValidation"
                      class="text-danger"
                    >
                      {{ postCodeError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-else>
                <b-form-group
                  label="Mailing Address 1"
                  label-for="h-address1"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing Address 1"
                    vid="mailingAddress1"
                    rules=""
                  >
                    <b-form-input
                      id="h-address1"
                      v-model="residentialAddress1"
                      placeholder="Mailing Address 1"
                      :state="(errors.length > 0 || mailingAddress1Validation) ? false : null"
                      name="mailingAddress1"
                      readonly
                      @input="mailingAddress1Validation == true ? mailingAddress1Validation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="mailingAddress1Validation"
                      class="text-danger"
                    >
                      {{ mailingAddress1Error }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Mailing Address 2"
                  label-for="h-address2"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing Address 2"
                    vid="mailingAddress2"
                    rules=""
                  >
                    <b-form-input
                      id="h-address2"
                      v-model="residentialAddress2"
                      placeholder="Mailing Address 2"
                      :state="(errors.length > 0 || mailingAddress2Validation) ? false : null"
                      readonly
                      name="mailingAddress2"
                      @input="mailingAddress2Validation == true ? mailingAddress2Validation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="mailingAddress2Validation"
                      class="text-danger"
                    >
                      {{ mailingAddress2Error }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  vid="cCountry"
                  rules=""
                >
                  <b-form-group
                    label="Country"
                    label-for="h-c-country"
                    label-cols-md="4"
                  >
                    <v-select
                      id="h-c-country"
                      v-model="country"
                      label="title"
                      :options="countryOptions"
                      :reduce="title => title.code"
                      :clearable="false"
                      disabled
                      @input="cCountryValidation == true ? cCountryValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="cCountryValidation"
                      class="text-danger"
                    >
                      {{ cCountryError }}
                    </small>
                  </b-form-group>
                </validation-provider>
                <b-form-group
                  label="Postal Code"
                  label-for="h-post-code"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Postal Code"
                    vid="postCode"
                    rules=""
                  >
                    <b-form-input
                      id="h-post-code"
                      v-model="postalCode"
                      placeholder="Postal Code"
                      :state="(errors.length > 0 || postCodeValidation) ? false : null"
                      readonly
                      name="postCode"
                      @input="postCodeValidation == true ? postCodeValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="postCodeValidation"
                      class="text-danger"
                    >
                      {{ postCodeError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="attachment"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Supporting Document(s)*
            </h3>
          </template>
          <b-row>
            <b-col
              cols="12"
            >
              <vue-dropzone
                id="event-images"
                ref="myVueDropzone"
                disabled
                :options="dropzoneOptions"
                :use-custom-slot="true"
                @vdropzone-files-added="processFile"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-error="handleError"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    Drop file here or click to upload
                  </h3>
                  <div class="subtitle">
                    Images should not bigger than 5 mb. JPEG or PDF only.
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mt-2"
                  >
                    Upload file
                  </b-button>
                </div>
              </vue-dropzone>
            </b-col>
          </b-row>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'customers-customers-show', params: { id: $route.params.id } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>

    <b-modal
      id="modal-customer-edit-confirm"
      ref="customer-edit-confirm-modal"
      modal-class="status-update-modal agent__customer-modal"
      footer-class="d-flex justify-content-between"
      body-class="reduce-padding-0"
      size="508"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitForm"
      @cancel="closeConfirmationModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header sm-margin">
          <img
            :src="flyIcon"
            alt="Fly Icon"
          >
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000; margin-top: 13px; margin-bottom: 13px;">
              Ready to Submit?
            </h4>
            <p style="color: #333333; margin-bottom: 20px;">
              Please be aware that any changes made will be applied to all files associated with this customer. Please ensure that all modifications are accurate and necessary.
            </p>
          </div>
          <hr style="margin-bottom: 20px;">
        </div>
      </template>
      <div class="agent__customer-modal-content">
        <b-form-checkbox
          v-model="checked"
          value="Yes"
        >
          <span style="font-size: 14px; font-weight: 400;">I have verified the information to be updated is correct, according to customer's submitted request form.</span>
        </b-form-checkbox>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-success"
          class="ml-0"
          @click="cancel()"
        >
          Back to editing
        </b-button>
        <b-button
          variant="success"
          type="button"
          class="ml-0"
          :disabled="!checked"
          @click="ok()"
        >
          <span class="align-middle">Acknowledge and submit</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BForm, BButton, BNav,
  VBTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store/index'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BNav,

    vSelect,
    flatPickr,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-tooltip': VBTooltip, Ripple },
  mixins: [heightTransition],
  data() {
    return {
    // eslint-disable-next-line global-require
      userAvatar: require('@/assets/images/avatars/2.png'),
      canViewThisAction,
      checked: '',
      savedData: false,
      customerDetail: {},
      serviceForm: {},
      // personal information
      name: '',
      nameError: 'Valid name is required',
      nameValidation: false,
      NRIC: '',
      NRICError: 'Valid NRIC is required',
      NRICValidation: false,
      sex: 'male',
      sexError: 'Valid value is required',
      sexValidation: false,
      race: '',
      raceError: 'Valid race is required',
      raceValidation: false,
      marital: 'married',
      maritalError: 'Valid value is required',
      maritalValidation: false,
      disabledDob: '',
      dob: '',
      dobError: 'Valid date is required',
      dobValidation: false,
      nationality: 'singapore',
      nationalityError: 'Valid value  is required',
      nationalityValidation: false,
      country: 'singapore',
      countryError: 'Valid value  is required',
      countryValidation: false,
      homePhoneNo: '',
      homePhoneNoError: 'Valid phone no is required',
      homePhoneNoValidation: false,
      residentialAddress1: '',
      residentialAddress1Error: 'Valid address is required',
      residentialAddress1Validation: false,
      residentialAddress2: '',
      residentialAddress2Error: 'Valid address is required',
      residentialAddress2Validation: false,
      postalCode: '',
      postalCodeError: 'Valid postal code is required',
      postalCodeValidation: false,

      // correspondence contact
      mobileNo: '',
      mobileNoError: 'Valid mobile no is required',
      mobileNoValidation: false,
      emailAddress: '',
      emailAddressError: 'Valid email address is required',
      emailAddressValidation: false,
      hasDifferentAddress: '',
      mailingAddress1: '',
      mailingAddress1Error: 'Valid mailing address 1 is required',
      mailingAddress1Validation: false,
      mailingAddress2: '',
      mailingAddress2Error: 'Valid mailing address 2 is required',
      mailingAddress2Validation: false,
      postCode: '',
      postCodeError: 'Valid postal code is required',
      postCodeValidation: false,
      cCountry: 'singapore',
      cCountryError: 'Valid value  is required',
      cCountryValidation: false,

      updateTitle: '',
      selectedIndex: 0,
      attachments: [],
      customFields: [],
      previousValues: [],
      statusOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      genderOptions: [
        { title: 'MALE', code: 'MALE' },
        { title: 'FEMALE', code: 'FEMALE' },
        { title: 'N/A', code: 'N/A' },
      ],
      raceOptions: [
        { title: 'CHINESE', code: 'CHINESE' },
        { title: 'INDIAN', code: 'INDIAN' },
        { title: 'N/A', code: 'N/A' },
        { title: 'OTHERS', code: 'OTHERS' },
      ],
      nationalityOptions: [
        { title: 'SINGAPOREAN', code: 'SINGAPOREAN' },
        { title: 'MALAYSIAN', code: 'MALAYSIAN' },
        { title: 'OTHERS', code: 'OTHERS' },
      ],
      countryOptions: [
        { title: 'SINGAPORE', code: 'SINGAPORE' },
        { title: 'MALAYSIA', code: 'MALAYSIA' },
        { title: 'OTHERS', code: 'OTHERS' },
      ],
      maritalOptions: [
        { title: 'DIVORCED', code: 'DIVORCED' },
        { title: 'MARRIED', code: 'MARRIED' },
        { title: 'OTHERS', code: 'OTHERS' },
        { title: 'SINGLE', code: 'SINGLE' },
      ],
      // eslint-disable-next-line global-require
      flyIcon: require('@/assets/images/icons/fly.png'),
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      flatPickrConfigWithTime: {
        wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', disableMobile: true,
      },
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      flatPickrConfigTime: {
        wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true,
      },
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      // validation rules
      required,
    }
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.$http.get(`customer/customers/${this.$route.params.id}`)
      .then(response => {
        this.customerDetail = response.data.data
        const customer = response.data.data
        const bcLength = store.state.breadcrumb.breadcrumbs.length
        if (bcLength > 1) {
          store.commit('breadcrumb/REMOVE_BREADCRUMB')
        }
        if (customer) {
          const newMeta = {
            active: true,
            text: `Editing ${customer.stringID} - ${customer.name}`,
          }
          store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
        }

        // personal information
        this.name = customer.name || ''
        this.NRIC = customer.nric || ''
        this.sex = customer.sex || ''
        this.race = customer.race || ''
        this.dob = customer.dob || ''
        this.disabledDob = customer.dob ? this.dateFormat(customer.dob) : ''
        this.nationality = customer.nationality || ''
        this.country = customer.perCountry || ''
        this.homePhoneNo = customer.perContactHome || ''
        this.residentialAddress1 = customer.perAddress1 || ''
        this.residentialAddress2 = customer.perAddress2 || ''
        this.postalCode = customer.perPostcode || ''
        this.hasDifferentAddress = customer.isSameAddress ? '' : 'Yes'

        // corresponding information
        this.mobileNo = customer.corContact || ''
        this.emailAddress = customer.corEmail || ''
        this.mailingAddress1 = customer.corAddress1 || ''
        this.mailingAddress2 = customer.corAddress2 || ''
        this.postCode = customer.corPostcode || ''
        this.cCountry = customer.corCountry || ''

        document.title = `Editing ${customer.stringID} - ${customer.name} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`

        customer.attachments.forEach(arrayItem => {
          const file = {
            size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, type: arrayItem.type, description: arrayItem.description,
          }
          this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
          this.attachments.push(arrayItem)
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    setImage() {
      if (this.logo && this.logo.size <= 1000000) {
        const reader = new FileReader()
        reader.readAsDataURL(this.logo)
        reader.onload = () => {
          this.logoSrc = reader.result
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a smaller image',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'operation-service-forms-show', params: { id: this.$route.params.id } })
          }
        })
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 5 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)
              let description = 'Client Details Supporting Document(s)'
              if (this.attachments.length > 0) {
                description = `Client Details Supporting Document(s) ${this.attachments.length + 1}`
              }
              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = description
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async submitSaveFormConfirmation() {
      const success = await this.$refs.customerEditForm.validate()
      if (this.attachments.length > 0 && success) {
        this.$root.$emit('bv::show::modal', 'modal-customer-edit-confirm', '')
      } else {
        this.$swal({
          title: 'Some Details are Missing',
          html: 'Please upload supporting documents.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/question.png'),
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: 'Custom Icon',
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: 'Back to Editing',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    closeConfirmationModal() {
      this.$root.$emit('bv::hide::modal', 'modal-customer-edit-confirm', '')
    },
    submitForm() {
      this.$refs.customerEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          let corCountry = this.country
          let corPostcode = this.postalCode
          let codAdd1 = this.residentialAddress1
          let codAdd2 = this.residentialAddress2
          let isSameAddress = true
          if (this.hasDifferentAddress) {
            corCountry = this.cCountry
            corPostcode = this.postCode
            codAdd1 = this.mailingAddress1
            codAdd2 = this.mailingAddress2
            isSameAddress = false
          }

          formData.append('name', this.name)
          formData.append('nric', this.NRIC)
          formData.append('sex', this.sex)
          formData.append('race', this.race)
          formData.append('nationality', this.nationality)
          formData.append('dob', this.dob)
          formData.append('corEmail', this.emailAddress)
          formData.append('corContact', this.mobileNo)
          formData.append('corCountry', corCountry)
          formData.append('corAddress1', codAdd1)
          formData.append('corAddress2', codAdd2)
          formData.append('corPostcode', corPostcode)
          formData.append('perContactHome', this.homePhoneNo)
          formData.append('perAddress1', this.residentialAddress1)
          formData.append('perAddress2', this.residentialAddress2)
          formData.append('perPostcode', this.postalCode)
          formData.append('perCountry', this.country)
          formData.append('isSameAddress', isSameAddress)
          formData.append('attachments', JSON.stringify(this.attachments))

          this.$http.patch(`customer/customers/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.savedData = true
                this.$swal({
                  title: 'Customer Updated',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: true,
                  cancelButtonText: 'View Customer',
                  allowOutsideClick: false,
                  confirmButtonText: 'Return to Customers',
                  customClass: {
                    confirmButton: 'btn btn-outline-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                  width: '508px',
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'customers-customers-index' })
                    } else {
                      this.$router.push({ name: 'customers-customers-show', params: { id: response.data.data._id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'nric') {
                    this.NRICError = validationError.msg
                    this.NRICValidation = true
                  } else if (validationError.param === 'sex') {
                    this.sexError = validationError.msg
                    this.sexValidation = true
                  } else if (validationError.param === 'race') {
                    this.raceError = validationError.msg
                    this.raceValidation = true
                  } else if (validationError.param === 'nationality') {
                    this.nationalityError = validationError.msg
                    this.nationalityValidation = true
                  } else if (validationError.param === 'dob') {
                    this.dobError = validationError.msg
                    this.dobValidation = true
                  } else if (validationError.param === 'corEmail') {
                    this.emailAddressError = validationError.msg
                    this.emailAddressValidation = true
                  } else if (validationError.param === 'corContact') {
                    this.corContactError = validationError.msg
                    this.corContactValidation = true
                  } else if (validationError.param === 'corAddress2') {
                    this.corAddress2Error = validationError.msg
                    this.corAddress2Validation = true
                  } else if (validationError.param === 'corPostcode') {
                    this.corPostcodeError = validationError.msg
                    this.corPostcodeValidation = true
                  } else if (validationError.param === 'perContactHome') {
                    this.perContactHomeError = validationError.msg
                    this.perContactHomeValidation = true
                  } else if (validationError.param === 'perAddress1') {
                    this.perAddress1Error = validationError.msg
                    this.perAddress1Validation = true
                  } else if (validationError.param === 'perAddress2') {
                    this.perAddress2Error = validationError.msg
                    this.perAddress2Validation = true
                  } else if (validationError.param === 'perPostcode') {
                    this.perPostcodeError = validationError.msg
                    this.perPostcodeValidation = true
                  } else if (validationError.param === 'perCountry') {
                    this.perCountryError = validationError.msg
                    this.perCountryValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

  <style scoped>
    .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
      border-right-width: 1px !important;
    }
    .dropzone-custom-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .dropzone-custom-title {
      margin-top: 0;
      color: #104D9D;
    }
    .subtitle {
      color: #333333;
    }
    .vs__dropdown-option--disabled {
      background: #ededed;
      color: #000000;
    }
    .sd-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
    }
   .agent__media .media-body{
        display: flex;
        flex-direction: column;
        align-self: center;
   }

  </style>
