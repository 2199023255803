<template>
  <div>
    <b-table
      v-if="salesFiles.length"
      ref="refBillingDNTable"
      class="position-relative event_list min-height-200 shadow-table"
      :items="salesFiles"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <!-- Column: Role -->
      <template #cell(fileNo)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'File')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="text-bold align-middle detail-view-id"
            :to="{ name: 'customers-files-show', params: { id: data.item.fileID } }"
          >
            {{ data.item.fileNo }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="text-bold align-middle detail-view-id not-button"
          >
            {{ data.item.fileNo }}
          </b-button>
        </div>
      </template>

      <template #cell(nvsFileType)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.nvsFileType }}</span>
          <br>
          <span
            v-if="data.item.lotLocation"
            class=""
          >
            {{ data.item.lotLocation }}
          </span>
        </div>
      </template>

      <template #cell(purchasers)="data">
        <div
          v-for="(purchaser, key) in data.item.purchasers"
          :key="key"
          class=""
        >
          <span class="text-uppercase">{{ purchaser.name }}</span>
          <br>
          <span
            v-if="purchaser.nric && !Array.isArray(purchaser.nric)"
            class=""
          >
            {{ purchaser.nric[0] + purchaser.nric.slice(1).replace(/.(?=.{4,}$)/g, '*') }}
          </span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(nvsFileStatus)="data">
        <b-badge
          :variant="`light-${resolveFileStatusVariant(data.item.nvsFileStatus)}`"
          class="text-capitalize"
        >
          {{ data.item.nvsFileStatus ? data.item.nvsFileStatus.toLowerCase() : '-' }}
        </b-badge>
      </template>

      <!-- Column: Status -->
      <template #cell(paymentStatus)="data">
        <b-badge
          :variant="`light-${resolvePaymentStatusVariant(data.item.paymentStatus)}`"
          class="text-capitalize"
        >
          {{ data.item.paymentStatus }}
        </b-badge>
      </template>

      <template #cell(bookingDate)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.bookingDate ? dateFormat(data.item.bookingDate) : '-' }}</span>
        </div>
      </template>

      <template #cell(confirmDate)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.confirmDate ? dateFormat(data.item.confirmDate) : '-' }}</span>
        </div>
      </template>

      <template #cell(createdAt)="data">
        <div class="text-nowrap">
          <span class="">{{ getCreatedBy(data.item) }}</span>
          <br>
          <span class="">{{ getCreatedAt(data.item) }}</span>
        </div>
      </template>

      <template #bottom-row="">
        <td
          colspan="10"
        >
          <b-row>
            <b-col
              md="2"
              sm="2"
              class="my-25"
            >
              <b-form-group
                class="mb-0"
              >
                <label class="d-inline-block text-sm-left mr-50 weight-400 fs-14">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="10"
              sm="10"
              class="my-25"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                align="right"
                prev-text="Prev"
                next-text="Next"
              />
            </b-col>
          </b-row>
        </td>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No data found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, VBTooltip, BButton, BBadge, BPagination, BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    salesFiles: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'No',
      sortDesc: false,
      isSortDirDesc: false,
      tableItems: [],
      currentPage: 1,
      totalRows: this.salesFiles.length,
      perPage: 10,
      pageOptions: [10, 15, 20],
      tableColumns: [
        {
          key: 'fileNo',
          label: 'file No.',
          sortable: true,
        },
        {
          key: 'nvsFileType',
          label: 'Product Description',
          sortable: false,
        },
        {
          key: 'purchasers',
          label: 'customer',
          sortable: false,
        },
        {
          key: 'nvsFileStatus',
          label: 'file status',
          sortable: false,
        },
        {
          key: 'paymentStatus',
          label: 'payment status',
          sortable: false,
        },
        {
          key: 'bookingDate',
          label: 'booking date',
          sortable: false,
        },
        {
          key: 'confirmDate',
          label: 'confirm date',
          sortable: false,
        },
        {
          key: 'createdAt',
          label: 'Created By',
          sortable: false,
        },
      ],
    }
  },

  methods: {
    canViewThisAction,
    getCreatedBy(item) {
      if (item.createdByNvs) {
        return item.createdByNvs.name
      }
      if (item.createdBy) {
        return item.createdBy
      }

      return ''
    },
    getCreatedAt(item) {
      if (item.createdAtNvs) {
        return this.dateFormatWithTime(item.createdAtNvs)
      }
      if (item.createdAt) {
        return this.dateFormatWithFormatNTZ(item.createdAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    resolvePaymentStatusVariant(status) {
      if (status === 'Fully Paid') return 'success'
      if (status === 'Ongoing Installment') return 'info'
      if (status === 'Pending Payment') return 'warning'
      if (status === 'Reactivation Fee') return 'danger'
      return 'secondary'
    },
    splitNewLine(text, index) {
      const separateLines = text.split(/\r?\n|\r|\n/g)
      if (separateLines[index]) {
        return separateLines[index]
      }

      return ''
    },
  },
}
</script>
