<template>
  <div>
    <div
      class="p4a__receipt-pdf"
    >
      <div class="p4a__receipt-pdf">
        <div
          class="p4a__receipt-content"
        >
          <table style="width: 100%; border-collapse: separate; border-spacing: 10px 5px;">
            <thead style="display: table-header-group;">
              <tr>
                <td>
                  <!-- Header -->
                  <div
                    id="main-header"
                    class="invoice-padding pb-0"
                  >
                    <div
                      class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                    />
                    <!-- Spacer -->
                    <div
                      class="d-flex justify-content-between invoice-spacing mt-0"
                      style="font-family: Inter;"
                    >

                      <div
                        v-if="receipt.nvsFileType == 'Funeral Service Package'"
                        style="min-width: fit-content; width: fit-content;"
                      >
                        <h4 style="font-family: Eras Demi ITC; font-weight: 400; font-size: 30px; line-height: 30px; text-align: left; color: rgba(16, 77, 157, 1);">
                          Nirvana Memorial Garden Pte Ltd <span style="font-size: 11px; color: rgba(51, 51, 51, 1);">(200720733M)</span>
                        </h4>
                        <p style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                          GST Registration Number: M90364395L
                        </p>
                        <p style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                          950 Old Chua Chu Kang Road Singapore 699816 Tel: (65) 6397 2272    Fax: (65) 6795 3070
                        </p>
                      </div>
                      <div
                        v-else
                        style="min-width: fit-content; width: fit-content;"
                      >
                        <h4 style="font-family: Eras Demi ITC; font-weight: 400; font-size: 30px; line-height: 30px; text-align: left; color: rgba(16, 77, 157, 1);">
                          Mount Prajna Ltd <span style="font-size: 11px; color: rgba(51, 51, 51, 1);">(200401183W)</span>
                        </h4>
                        <p style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                          GST Registration Number: M90364395L
                        </p>
                        <p style="font-family: Inter; text-align: left; color: rgba(51, 51, 51, 1); font-weight: 400; font-size: 14px; line-height: 18.2px; margin-bottom: 0;">
                          950 Old Chua Chu Kang Road Singapore 699816 Tel: (65) 6397 2272    Fax: (65) 6795 3070
                        </p>
                      </div>

                      <div style="margin-top: 5px;">
                        <h4
                          class="invoice-title"
                          style="font-family: Inter; color: rgba(51, 51, 51, 1); font-weight: 700; font-size: 40px; line-height: 32px;"
                        >
                          Official Receipt
                        </h4>
                        <h6 style="font-family: Inter; color: rgba(51, 51, 51, 1); font-weight: 600; font-size: 24px; line-height: 32px; margin-bottom: 0;">
                          {{ receipt.trxNo }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <!-- Invoice Client & Payment Details -->
                  <div
                    class="invoice-padding main__body po__create preview_order_pdf"
                    style="padding-top: 15px;"
                  >
                    <div
                      style="display: flex; justify-content: space-between;"
                    >
                      <div>
                        <h4 class="purchase-info__title">
                          {{ purchaser.name }}
                        </h4>
                        <div class="purchase-info__single">
                          <span class="purchase-info__single-value">{{ purchaser.customer ? purchaser.customer.perAddress1 : '-' }}</span>
                        </div>
                        <div class="purchase-info__single">
                          <span class="purchase-info__single-value">{{ purchaser.customer ? purchaser.customer.perAddress2 : '-' }}</span>
                        </div>
                        <div class="purchase-info__single">
                          <span class="purchase-info__single-value">{{ purchaser.customer ? purchaser.customer.perCountry : '-' }} {{ purchaser.customer ? purchaser.customer.perPostcode : '-' }}</span>
                        </div>
                      </div>
                      <div
                        class="purchase-info"
                      >
                        <div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              File number
                            </strong>
                            <span class="purchase-info__single-value">{{ receipt.fileNo }}</span>
                          </div>
                          <div class="purchase-info__single">
                            <strong class="purchase-info__single-title">
                              Date
                            </strong>
                            <span class="purchase-info__single-value">{{ receipt.trxDate ? dateFormat(receipt.trxDate) : '-' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-row
                      style="margin-top: 36px"
                    >
                      <b-col
                        cols="12"
                      >
                        <b-table-simple
                          class="position-relative has_padding receipt-pdf__table"
                          responsive
                          caption-top
                        >
                          <b-thead>
                            <b-tr class="group__tag">
                              <th style="padding-left: 0;">
                                Transaction Date
                              </th>
                              <th>
                                Being Payment For
                              </th>
                              <th>Payment Mode</th>
                              <th style="text-align: right;">
                                Amount (SGD)
                              </th>
                            </b-tr>
                          </b-thead>
                          <b-tbody
                            class="white-body-background"
                          >
                            <tr
                              v-for="(item, index) in receipt.transactions"
                              :key="index"
                              style="page-break-inside: avoid; page-break-after: auto;"
                            >
                              <b-td
                                style="page-break-inside: avoid; page-break-after: auto;"
                              >
                                {{ receipt.trxDate ? dateFormat(receipt.trxDate) : '-' }}
                              </b-td>
                              <b-td>{{ receipt.nvsFileType }} {{ receipt.lotLocation }}</b-td>
                              <b-td>{{ item.paymentType }}</b-td>
                              <b-td style="text-align: right;">
                                <span class="text-bold-black">
                                  {{ item.amount ? numberFormat(item.amount) : '0.00' }}
                                </span>
                              </b-td>
                            </tr>
                            <tr>
                              <b-td colspan="2">
                                <p style="font-family: Inter; font-weight: 400; font-size: 18px; line-height: 21.78px; text-align: left; color: rgba(51, 51, 51, 1); white-space: wrap; margin-bottom: 0;">
                                  <strong>Remarks:</strong> {{ receipt.remarks || '-' }}
                                </p>
                                <p style="font-family: Inter; font-weight: 400; font-size: 18px; line-height: 21.78px; text-align: left; color: rgba(51, 51, 51, 1); white-space: wrap; margin-bottom: 0;">
                                  <strong>Payment:</strong> SGD {{ numberToWord(receipt.amount ? receipt.amount : '0.00') }} Only.
                                </p>
                              </b-td>
                              <b-td style="text-align: right; padding-top: 28px !important;">
                                <strong style="">Amount (SGD)</strong>
                              </b-td>
                              <b-td style="text-align: right;">
                                <div style="min-width: 130px; border-top: 1px solid rgba(51, 51, 51, 1); border-bottom: 4px double rgba(51, 51, 51, 1); padding: 10px 0 10px 63px; display: inline-block;">
                                  <strong>{{ receipt.amount ? numberFormat(receipt.amount) : '0.00' }}</strong>
                                </div>
                              </b-td>
                            </tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div style="padding-top: 80px; padding-left: 14px;">
      <strong style="font-family: Inter; font-size:14px; font-weight: 700; line-height: 16.94px; text-align: left; text-transform: uppercase; color: rgba(51, 51, 51, 1);">Registrar’s Copy</strong>
      <p style="font-family: Inter; font-size:14px; font-weight: 400; line-height: 16.94px; text-transform: uppercase; text-align: left; color: rgba(51, 51, 51, 1);">
        this is a computer-generated receipt. No signature is required.
      </p>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BTableSimple, BTbody, BThead, BTr, BTd,
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTd,

  },
  data() {
    return {
      todayDate: moment().format('DD MMMM YYYY'),
      receipt: localStorage.getItem('customerReceiptDetail') ? JSON.parse(localStorage.getItem('customerReceiptDetail')) : {},
      purchaser: {},
    }
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.purchaser = (this.receipt.purchasers && this.receipt.purchasers.length) ? this.receipt.purchasers[0] : {}
  },
  beforeMount() {
    if (this.$route.params.id && this.receipt && this.receipt._id === this.$route.params.id) {
      const formData = new FormData()
      formData.append('url', window.location.origin)
      formData.append('receiptID', this.receipt.trxID)
      formData.append('id', this.$route.params.id)
      this.$http.post('front-end/receipts/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Receipt-${this.receipt.trxNo}.pdf`
          link.click()

          link.remove()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
}
</script>

<style lang="scss">
    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .card {
        box-shadow: none !important;
    }
    .receipt-pdf__table table{
        border: none;
    }
    .receipt-pdf__table table tr th{
        border-top: none;
        background: #7d7d7d00 !important;
        border-bottom: 2px solid rgba(102, 102, 102, 0.1);
        font-family: Inter;
        font-size: 18px !important;
        font-weight: 600;
        line-height: 21.78px;
        text-align: left;
        color: rgba(0, 0, 0, 1) !important;
        text-transform: capitalize;
        &:first-child{
          padding-left: 0 !important;
        }
    }
    .receipt-pdf__table table tr td{
        border-top: none;
        font-family: Inter;
        font-size: 18px !important;
        font-weight: 400;
        line-height: 21.74px;
        text-align: left;
        color: rgba(0, 0, 0, 1) !important;
        background: #7d7d7d00 !important;
        &:first-child{
            padding-left: 0 !important;
        }
    }
    .td__bg-white {
      background: #fff !important;
    }
    .td__border{
        border-top: 2px solid rgba(0, 0, 0, 1) !important;
        border-bottom: 2px solid rgba(0, 0, 0, 1) !important;
    }
    .purchase-info{
        min-width: 294px;
        margin-top: 34px;
    }
    .purchase-info__title{
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
    .purchase-info__single{
        display: flex;
        justify-content: space-between;
    }
    .purchase-info__single-title{
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      text-align: left;
    }
    .purchase-info__single-value{
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
    }
    .soa__pdf-hr{
        height: 2px;
        width: 100%;
        background:rgba(102, 102, 102, 0.1);
        margin-right: 1rem;
        margin-left: 1rem;
    }
</style>
<style>
    @media print {
      html, body {
        height: 99%;
      }

      .p4a__receipt-content{
          padding: 0 !important;
      }
    }
</style>
