<template>
  <div class="frontend w-100 d-flex justify-content-center align-items-center vh-100">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
  </div>
</template>

<script>
import Loader from './Loader.vue'

export default {
  components: {
    Loader,
  },
}
</script>
