<template>
  <div class="p4b__parent p4b__pb-40">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="container p4b__pending-payment__page">
      <div
        v-if="isMobile()"
        class="p4b__mobile-back-btn"
      >
        <b-button
          variant="flat"
          class="btn-icon"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="24"
            icon="ArrowLeftIcon"
          />
        </b-button>
      </div>
      <div class="p4b__toc-logo">
        <img
          :src="logoImg"
          alt="Logo"
        >
      </div>
      <b-row v-if="showPayment">
        <b-col md="7">
          <h2 class="p4b__pending-payment-title">
            Pending payment items
          </h2>
          <b-card>
            <b-card-text class="p4b__toc-text-block">
              <b-media
                left-align
                vertical-align="center"
                class="p4b__pending-payment-media"
              >
                <template #aside>
                  <b-avatar
                    size="80"
                    rounded
                    class="no-background"
                    :src="fileDetail.thumb"
                  />
                </template>
                <div class="d-flex justify-content-between">
                  <h5 class="mt-0">
                    {{ fileDetail.purchasers.length ? fileDetail.purchasers[0].name : '' }}
                  </h5>
                  <b-badge
                    v-if="fileDetail.installmentStatus == 'OPEN'"
                    variant="primary"
                  >
                    Installment
                  </b-badge>
                </div>
                <h6>{{ fileDetail.nvsFileType }}</h6>
                <h6 v-if="fileDetail.lotLocation">
                  {{ fileDetail.lotLocation }}
                </h6>
                <p>
                  File no. {{ fileDetail.fileNo }}
                </p>
                <p>
                  {{ fileDetail.confirmDate ? dateFormatWithFormatParam(fileDetail.confirmDate, 'MMM DD YYYY') : '-' }} • {{ fileDetail.purchasers.length ? fileDetail.purchasers[0].name : '' }} {{ fileDetail.purchasers.length ? (fileDetail.purchasers.length > 1 ? `+${fileDetail.purchasers.length - 1}` : '') : '' }}
                </p>
              </b-media>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="5">
          <b-card
            class="p4b__payment-list-card"
            body-class="p4b__payment-list-card-body"
          >
            <b-card-text v-if="otherFees.length">
              <div class="p4b__payment-list-top">
                <h3>Reactivation fee(s)</h3>
                <b-button
                  v-if="otherFees.length > selectedOtherFees.length"
                  @click="selectAllFees"
                >
                  Select all
                </b-button>
                <b-button
                  v-if="otherFees.length == selectedOtherFees.length"
                  @click="unselectAllFees"
                >
                  Unselect all
                </b-button>
              </div>
              <b-card
                v-for="(item, index) in otherFees"
                :key="index"
                class="p4b__reactivation-card"
              >
                <div
                  class="d-flex justify-content-between"
                >
                  <div>
                    <h3>${{ numberFormat(item.balance) }}</h3>
                    <h6>Reactivation fee</h6>
                    <p>{{ item.trxDate ? dateFormatWithFormatParam(item.trxDate, 'MMM DD, YYYY') : '-' }}</p>
                  </div>
                  <div class="p4b__reactive-checkbox">
                    <b-form-checkbox
                      v-model="selectedOtherFees"
                      class="custom-control-success"
                      :value="item.trxID"
                      @click.native.stop
                      @change="selectOtherFee(item)"
                    />
                  </div>
                </div>
              </b-card>
            </b-card-text>
            <b-card-text v-if="installmentList.length">
              <div class="p4b__payment-list-top">
                <h3>Installments</h3>
                <b-button
                  v-if="installmentList.length > selectedInstallments.length"
                  @click="selectAllInstallments"
                >
                  Select all
                </b-button>
                <b-button
                  v-if="installmentList.length == selectedInstallments.length"
                  @click="unselectAllInstallments"
                >
                  Unselect all
                </b-button>
              </div>
              <b-card
                v-for="(installment, index) in installmentList"
                :key="index"
                class="p4b__installment-card"
              >
                <div class="d-flex justify-content-between">
                  <div class="p4b__payment-status">
                    <b-badge
                      class="p4b__payment-badge"
                      :class="{ 'p4b__badge-warning': installment.paymentStatus === 'Due', 'p4b__badge-danger': installment.paymentStatus === 'Overdue', 'p4b__badge-base': installment.paymentStatus === 'Upcoming' }"
                    >
                      {{ installment.paymentStatus }}
                    </b-badge>
                    <p>Due {{ dateFormatWithFormatParam(installment.dueDate, 'MMM DD, YYYY') }}</p>
                  </div>
                  <div class="p4b__payment-border-left">
                    <div class="p4b__payment-installment-item">
                      <span
                        v-if="installment.principleAmount > installment.remaining"
                        class="text-muted-o"
                      >
                        Partially Paid
                      </span>
                      <h3>${{ numberFormat(installment.remaining) }}</h3>
                      <h6>Installment #{{ installment.sequence }}</h6>
                    </div>
                  </div>
                  <div class="p4b__reactive-checkbox">
                    <b-form-checkbox
                      v-model="selectedInstallments"
                      class="custom-control-success"
                      :value="installment.sequence"
                      @click.native.stop
                      @change="selectInstallment(installment)"
                    />
                  </div>
                </div>
              </b-card>
            </b-card-text>
            <b-card-text v-if="otherInvoices.length">
              <div class="p4b__payment-list-top">
                <h3>Other Invoices</h3>
                <b-button
                  v-if="otherInvoices.length > selectedInvoices.length"
                  @click="selectAllInvoices"
                >
                  Select all
                </b-button>
                <b-button
                  v-if="otherInvoices.length == selectedInvoices.length"
                  @click="unselectAllInvoices"
                >
                  Unselect all
                </b-button>
              </div>
              <b-card
                v-for="(item, index) in otherInvoices"
                :key="index"
                class="p4b__reactivation-card"
              >
                <div
                  class="d-flex justify-content-between"
                >
                  <div>
                    <h3>${{ numberFormat(item.balance) }}</h3>
                    <h6>{{ item.trxNo }}</h6>
                    <p>{{ item.trxDate ? dateFormatWithFormatParam(item.trxDate, 'MMM DD, YYYY') : '-' }}</p>
                  </div>
                  <div class="p4b__reactive-checkbox">
                    <b-form-checkbox
                      v-model="selectedInvoices"
                      class="custom-control-success"
                      :value="item.trxID"
                      @click.native.stop
                      @change="selectInvoice(item)"
                    />
                  </div>
                </div>
              </b-card>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div class="p4b__btn-section">
      <b-button
        class="p4b__payment-btn-common"
        block
        :disabled="totalPayable <= 0"
        @click="goToPreview"
      >
        <feather-icon
          icon="DollarSignIcon"
          size="16"
          class="mr-75"
          style="vertical-align: middle;"
        />{{ paymentButtonText }}
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton, BCard, BCardText, BRow, BCol, BMedia, BAvatar, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from '../Loader.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BBadge,
    BFormCheckbox,

    Loader,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-yellow.png'),
      // eslint-disable-next-line global-require
      visaCard: require('@/assets/images/frontend/p4b/payment-logo/visa.png'),
      // eslint-disable-next-line global-require
      masterCard: require('@/assets/images/frontend/p4b/payment-logo/mastercard.png'),
      // eslint-disable-next-line global-require
      grabPay: require('@/assets/images/frontend/p4b/payment-logo/grab-pay.png'),
      // eslint-disable-next-line global-require
      payNow: require('@/assets/images/frontend/p4b/payment-logo/pay-now.png'),
      // eslint-disable-next-line global-require
      googlePay: require('@/assets/images/frontend/p4b/payment-logo/google-pay.png'),

      reactiveFee: false,
      showPayment: false,
      fileDetail: {},
      tokenDetail: {},
      installmentList: [],
      selectedInstallments: [],
      selectedInstallmentObjects: [],
      selectedInvoiceObjects: [],
      selectAllOtherFeesObjects: [],
      selectedOtherFees: [],
      selectedInvoices: [],
      otherFees: [],
      otherInvoices: [],
      totalPayable: 0,
    }
  },
  computed: {
    paymentButtonText() {
      const length = this.selectedOtherFees.length + this.selectedInstallments.length + this.selectedInvoices.length
      if (length > 1) {
        return `Pay ${length} items ($${this.numberFormat(this.totalPayable)})`
      }
      if (length === 1) {
        return `Pay ${length} item ($${this.numberFormat(this.totalPayable)})`
      }
      return 'Pay'
    },
  },
  beforeMount() {
    document.body.classList.add('p4b__payment-bg')
    const { token } = this.$route.params
    if (token) {
      this.$http.get('front-end/payment/validate/token', { params: { token } })
        .then(response => {
          if (response.data.success) {
            if (response.data.validToken) {
              this.showPayment = true
              this.fileDetail = response.data.file || {}
              this.tokenDetail = response.data.token || {}
              this.otherFees = response.data.reactivationFees
              this.otherInvoices = response.data.invoices
              if (this.fileDetail.installmentSchedule && this.fileDetail.installmentSchedule.length > 0) {
                const filteredInstallments = this.fileDetail.installmentSchedule.filter(o => o.paymentStatus !== 'Paid')
                this.installmentList = filteredInstallments
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },

  methods: {
    unselectAllFees() {
      let subtractedAmount = 0
      this.otherFees.forEach(element => {
        const foundIndex = this.selectAllOtherFeesObjects.indexOf(element)
        if (foundIndex !== -1) {
          if (element.balance > 0) {
            subtractedAmount += Number(element.balance)
            this.selectedOtherFees.splice(this.selectedOtherFees.indexOf(element.trxID), 1)
            this.selectAllOtherFeesObjects.splice(foundIndex, 1)
          }
        }
      })
      this.totalPayable -= subtractedAmount
    },
    selectAllFees() {
      let addedAmount = 0
      this.otherFees.forEach(element => {
        const foundIndex = this.selectAllOtherFeesObjects.indexOf(element)
        if (foundIndex === -1) {
          if (element.balance > 0) {
            addedAmount += Number(element.balance)
            this.selectedOtherFees.push(element.trxID)
            // eslint-disable-next-line no-param-reassign
            element.allocated = element.balance
            this.selectAllOtherFeesObjects.push(element)
          }
        }
      })
      this.totalPayable += addedAmount
    },
    selectOtherFee(invoice) {
      const foundIndex = this.selectAllOtherFeesObjects.indexOf(invoice)
      if (foundIndex === -1) {
        if (invoice.balance > 0) {
          this.totalPayable += Number(invoice.balance)
          // eslint-disable-next-line no-param-reassign
          invoice.allocated = invoice.balance
          this.selectAllOtherFeesObjects.push(invoice)
        } else {
          this.selectedOtherFees.splice(this.selectedOtherFees.indexOf(invoice.trxID), 1)
        }
      } else {
        this.totalPayable -= Number(invoice.balance)
        this.selectAllOtherFeesObjects.splice(foundIndex, 1)
      }
    },
    selectAllInstallments() {
      this.selectedInstallments = []
      let reducedAmount = 0
      this.selectedInstallmentObjects.forEach(installment => {
        reducedAmount += Number(installment.remaining)
      })
      this.selectedInstallmentObjects = []
      let addedAmount = 0
      this.fileDetail.installmentSchedule.forEach(element => {
        if (element.remaining > 0) {
          addedAmount += Number(element.remaining)
          this.selectedInstallments.push(element.sequence)
          // eslint-disable-next-line no-param-reassign
          element.allocated = element.remaining
          this.selectedInstallmentObjects.push(element)
        }
      })
      this.selectedInstallmentObjects = this.selectedInstallmentObjects.sort((a, b) => a.sequence - b.sequence)
      this.totalPayable += (addedAmount - reducedAmount)
    },
    unselectAllInstallments() {
      this.selectedInstallments = []
      let reducedAmount = 0
      this.selectedInstallmentObjects.forEach(installment => {
        reducedAmount += Number(installment.remaining)
      })
      this.selectedInstallmentObjects = []
      this.totalPayable -= reducedAmount
    },
    selectInstallment(installment) {
      const foundIndex = this.selectedInstallmentObjects.indexOf(installment)
      if (foundIndex === -1) {
        if (installment.remaining > 0) {
          // eslint-disable-next-line no-param-reassign
          installment.allocated = installment.remaining
          this.selectedInstallmentObjects.push(installment)
          let addedAmount = 0
          this.fileDetail.installmentSchedule.forEach(element => {
            if (this.selectedInstallmentObjects.indexOf(element) === -1 && element.remaining > 0 && element.sequence < installment.sequence) {
              addedAmount += Number(element.remaining)
              this.selectedInstallments.push(element.sequence)
              // eslint-disable-next-line no-param-reassign
              element.allocated = element.remaining
              this.selectedInstallmentObjects.push(element)
            }
          })
          this.selectedInstallmentObjects = this.selectedInstallmentObjects.sort((a, b) => a.sequence - b.sequence)
          this.totalPayable += (addedAmount + Number(installment.remaining))
        } else {
          this.selectedInstallments.splice(this.selectedInstallments.indexOf(installment.sequence), 1)
        }
      } else {
        this.selectedInstallmentObjects.splice(this.selectedInstallmentObjects.indexOf(installment), 1)
        const selectedInObjs = this.selectedInstallmentObjects.filter(seInstallment => seInstallment.sequence > installment.sequence)
        let reducedAmount = 0
        selectedInObjs.forEach(element => {
          this.selectedInstallments.splice(this.selectedInstallments.indexOf(element.sequence), 1)
          this.selectedInstallmentObjects.splice(this.selectedInstallmentObjects.indexOf(element), 1)
          reducedAmount += Number(element.allocated)
        })
        this.selectedInstallmentObjects = this.selectedInstallmentObjects.sort((a, b) => a.sequence - b.sequence)
        this.totalPayable -= (reducedAmount + Number(installment.allocated))
      }
    },
    selectAllInvoices() {
      let addedAmount = 0
      this.otherInvoices.forEach(element => {
        const foundIndex = this.selectedInvoiceObjects.indexOf(element)
        if (foundIndex === -1) {
          if (element.balance > 0) {
            addedAmount += Number(element.balance)
            this.selectedInvoices.push(element.trxID)
            // eslint-disable-next-line no-param-reassign
            element.allocated = element.balance
            this.selectedInvoiceObjects.push(element)
          }
        }
      })
      this.totalPayable += addedAmount
    },
    unselectAllInvoices() {
      let subtractedAmount = 0
      this.otherInvoices.forEach(element => {
        const foundIndex = this.selectedInvoiceObjects.indexOf(element)
        if (foundIndex !== -1) {
          if (element.balance > 0) {
            subtractedAmount += Number(element.balance)
            this.selectedInvoices.splice(this.selectedInvoices.indexOf(element.trxID), 1)
            this.selectedInvoiceObjects.splice(foundIndex, 1)
          }
        }
      })
      this.totalPayable -= subtractedAmount
    },
    selectInvoice(invoice) {
      const foundIndex = this.selectedInvoiceObjects.indexOf(invoice)
      if (foundIndex === -1) {
        if (invoice.balance > 0) {
          this.totalPayable += Number(invoice.balance)
          // eslint-disable-next-line no-param-reassign
          invoice.allocated = invoice.balance
          this.selectedInvoiceObjects.push(invoice)
        } else {
          this.selectedInvoices.splice(this.selectedInvoices.indexOf(invoice.trxID), 1)
        }
      } else {
        this.totalPayable -= Number(invoice.balance)
        this.selectedInvoiceObjects.splice(foundIndex, 1)
      }
    },

    goToPreview() {
      localStorage.setItem('paymentSelectionDetail', JSON.stringify({
        totalPayable: this.totalPayable,
        selectedOtherFees: this.selectAllOtherFeesObjects,
        selectedInstallments: this.selectedInstallmentObjects,
        selectedInvoices: this.selectedInvoiceObjects,
        fileDetail: this.fileDetail,
        tokenDetail: this.tokenDetail,
      }))
      this.$router.push({ name: 'file-payment-frontend-preview', params: { token: this.$route.params.token } })
    },
  },
}
</script>
